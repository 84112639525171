import axios from "axios";
import { API_URL } from "../api";
import { SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { logout } from "./authActions";

export const downloadFile = params => async dispatch => {
  try {
    const response = await axios.get(
      `${API_URL}/api/admin/report?report_type=${params}`
    );

    if (!response.data.file) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Lo sentimos, error en la extension del archivo."]
      });

      return {
        status: 422
      };
    }

    return response;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error;
  }
};
