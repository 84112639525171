import {
  AREAS_LOADING,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,
  SET_AREAS_SEARCH,
  SET_AREAS_PAGE,
  GET_AREA_INFO_SUCCESS,
  GET_AREA_INFO_FAILURE,
  RESET_AREAS_REDUCER
} from "../actions/types/areas";

const initialState = {
  areas: [],
  area: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    query: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AREAS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_AREAS_SUCCESS: {
      return {
        ...state,
        areas: [...action.payload.areas],
        loading: false,
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_AREAS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_AREAS_SEARCH: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_AREAS_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case GET_AREA_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        area: {
          ...state.area,
          ...action.payload.area
        }
      };
    }

    case GET_AREA_INFO_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case RESET_AREAS_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
