import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import ClinicsContainer from "./ClinicsContainer";
import ClinicCreate from "./ClinicCreate";
import ClinicUpdate from "./ClinicUpdate";
import ClinicSingle from "./ClinicSingle";

const ClinicsRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={ClinicsContainer} />
      <Route path={`${match.path}/add`} component={ClinicCreate} />
      <Route path={`${match.path}/:id/edit`} component={ClinicUpdate} />
      <Route path={`${match.path}/:id`} component={ClinicSingle} />
    </Switch>
  </Fragment>
);

export default ClinicsRoutes;
