import React from "react";
import PropTypes from "prop-types";
import { Pagination, PaginationItem, Row, Col } from "reactstrap";
import styled from "styled-components";

import {
  FaAngleRight,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleDoubleLeft
} from "react-icons/fa";

const PaginationLink = styled.button`
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: ${props =>
    props.active ? props.theme.whiteColor : props.theme.primaryColor};
  background-color: ${props =>
    props.active ? props.theme.primaryColor : props.theme.whiteColor};
  border: 1px solid #dee2e6;
`;

const Paginator = ({
  currentPage,
  first,
  last,
  next,
  prev,
  handlePaginator
}) => {
  let firstPage = first && parseInt(new URL(first).searchParams.get("page"));
  let lastPage = last && parseInt(new URL(last).searchParams.get("page"));
  let nextPage = next && parseInt(new URL(next).searchParams.get("page"));
  let prevPage = prev && parseInt(new URL(prev).searchParams.get("page"));

  return (
    <Row className="py-4">
      <Col>
        <Pagination aria-label="Page navigation example">
          {firstPage !== currentPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(firstPage)}>
                <FaAngleDoubleLeft />
              </PaginationLink>
            </PaginationItem>
          )}

          {prevPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage - 1)}>
                <FaAngleLeft />
              </PaginationLink>
            </PaginationItem>
          )}

          {currentPage - 4 >= firstPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage - 4)}>
                {currentPage - 4}
              </PaginationLink>
            </PaginationItem>
          )}

          {currentPage - 3 >= firstPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage - 3)}>
                {currentPage - 3}
              </PaginationLink>
            </PaginationItem>
          )}

          {currentPage - 2 >= firstPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage - 2)}>
                {currentPage - 2}
              </PaginationLink>
            </PaginationItem>
          )}

          {currentPage - 1 >= firstPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage - 1)}>
                {currentPage - 1}
              </PaginationLink>
            </PaginationItem>
          )}

          {currentPage && (
            <PaginationItem>
              <PaginationLink active>{currentPage}</PaginationLink>
            </PaginationItem>
          )}

          {currentPage + 1 <= lastPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage + 1)}>
                {currentPage + 1}
              </PaginationLink>
            </PaginationItem>
          )}

          {currentPage + 2 <= lastPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage + 2)}>
                {currentPage + 2}
              </PaginationLink>
            </PaginationItem>
          )}

          {currentPage + 3 <= lastPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage + 3)}>
                {currentPage + 3}
              </PaginationLink>
            </PaginationItem>
          )}

          {currentPage + 4 <= lastPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage + 4)}>
                {currentPage + 4}
              </PaginationLink>
            </PaginationItem>
          )}

          {nextPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(currentPage + 1)}>
                <FaAngleRight />
              </PaginationLink>
            </PaginationItem>
          )}

          {lastPage !== currentPage && (
            <PaginationItem>
              <PaginationLink onClick={() => handlePaginator(lastPage)}>
                <FaAngleDoubleRight />
              </PaginationLink>
            </PaginationItem>
          )}
        </Pagination>
      </Col>
    </Row>
  );
};

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  first: PropTypes.string.isRequired,
  prev: PropTypes.string,
  next: PropTypes.string,
  handlePaginator: PropTypes.func.isRequired
};

export default Paginator;
