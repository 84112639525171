import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import MarkerImg from "../images/marker.png";

const Marker = () => (
  <img
    src={MarkerImg}
    alt="marker"
    style={{ width: "30px" }}
    className="img-fluid"
  />
);

class Map extends Component {
  render() {
    const { lat, lng } = this.props.center;

    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCD1ADeb4qsAXrzrIHz_T5ayZmtbeXDv38" }}
        center={this.props.center}
        defaultZoom={this.props.zoom}
        options={{
          draggable: true,
          fullscreenControl: false,
          scaleControl: false,
          scrollwheel: false,
          navigationControl: false,
          streetViewControl: false
        }}
      >
        <Marker lat={lat} lng={lng} />
      </GoogleMapReact>
    );
  }
}

export default Map;
