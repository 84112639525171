import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import VirtualizedSelect from "react-virtualized-select";

import { connect } from "react-redux";
import { addPharmacy } from "../../actions/pharmacieActions";
import {
  getProvincies,
  getLocationById,
  getLocationInfoByName,
  getAreaById
} from "../../actions/addressActions";

import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import PageTransition from "../styles/PageTransition";
import PrimaryBtn from "../PrimaryBtn";
import ContainerHeader from "../styles/ContainerHeader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import Map from "../Map";
import Select from "react-select";



const options = [
  { value: '0', label: 'No Borrar' },
  { value: '1', label: 'Borrar al sincronizar' },

]

const PharmacyCreate = ({
  loading,
  provincies,
  locations,
  history,
  locationsLoading,
  getLocationById,
  addPharmacy,
  provinciesLoading,
  getProvincies,
  getLocationInfoByName,
  areas,
  areasLoading,
  getAreaById
}) => {
  const [latLong, setLatLong] = useState({
    lat: -34.6036844,
    lng: -58.381559100000004
  });

  useEffect(() => {
    const fetchProvinces = async () => {
      await getProvincies();
    };

    fetchProvinces();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs loading={loading} title="Nueva Farmacia" />
        </ContainerHeader>
        <Row className="mb-5">
          <Col sm={6}>
            <ElementWrapper className="p-3">
              <Formik
                initialValues={{
                  name: "",
                  address: "",
                  postcode: "",
                  phone: "",
                  province_id: "",
                  location_id: "",
                  lat: "",
                  lon: "",
                  area_id: "",
                  email:"",
                  manual:""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const response = await addPharmacy(values);

                  if (response !== 200) {
                    setSubmitting(false);
                    return;
                  }

                  setSubmitting(false);
                  history.push(`/pharmacies`);
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Requerido."),
                  address: Yup.string()
                    .min(5, "La dirección debe contener al menos 5 caracteres.")
                    .required("Requerido"),
                  postcode: Yup.string().required("Requerido"),
                  province_id: Yup.number()
                    .required("Requerido")
                    .nullable(),
                  location_id: Yup.number()
                    .required("Requerido")
                    .nullable(),
                  phone: Yup.string().required("Requerido"),
                  area_id: Yup.number()
                    .required("Requerido")
                    .nullable()
                })}
                render={({
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                      <FormGroup>
                        <Label for="name">Nombre:</Label>
                        <Input
                          id="name"
                          type="text"
                          placeholder="Ingrese el nombre"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${errors.name &&
                            touched.name &&
                            "is-invalid"}`}
                        />
                        {errors.name && touched.name && (
                          <FormFeedback className="d-block">
                            {errors.name}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="address">Dirección:</Label>
                        <PlacesAutocomplete
                          value={values.address}
                          onChange={address =>
                            setFieldValue("address", address)
                          }
                          onSelect={async place => {
                            setFieldValue("address", place);
                            const results = await geocodeByAddress(place);
                            const { lat, lng } = await getLatLng(results[0]);

                            const comunaName = results[0].address_components.find(
                              item =>
                                item.types[0] ===
                                  "administrative_area_level_2" ||
                                item.types[0] === "administrative_area_level_1"
                            );

                            const postalCode = results[0].address_components.find(
                              item => item.types[0] === "postal_code"
                            );

                            const provinceId = await getLocationInfoByName(
                              comunaName.long_name
                            );

                            if (postalCode) {
                              setFieldValue("postcode", postalCode.long_name);
                            }

                            if (provinceId) {
                              setFieldValue("province_id", provinceId);
                              await Promise.all([
                                getAreaById(provinceId),
                                getLocationById(provinceId)
                              ]);
                            }

                            setLatLong({
                              ...latLong,
                              lat,
                              lng
                            });

                            setFieldValue("lat", lat);
                            setFieldValue("lon", lng);
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading
                          }) => (
                            <>
                              <input
                                {...getInputProps({
                                  placeholder: "Buscar dirección...",
                                  className: `form-control ${errors.address &&
                                    touched.address &&
                                    "is-invalid"}`
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && (
                                  <div className="my-1 px-2">
                                    <Spinner size="sm" />
                                  </div>
                                )}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? "suggestion-item--active"
                                    : "suggestion-item";
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className
                                      })}
                                    >
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </PlacesAutocomplete>
                        {errors.address && touched.address && (
                          <FormFeedback className="d-block">
                            {errors.address}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="province_id">Provincia:</Label>
                        <VirtualizedSelect
                          id="province_id"
                          value={
                            provincies
                              ? provincies.find(
                                  province =>
                                    province.value === values.province_id
                                )
                              : ""
                          }
                          options={provincies}
                          onChange={async value => {
                            if (value) {
                              const id = value.value;
                              setFieldValue("province_id", id);
                              await getAreaById(id);
                              setFieldValue("location_id", "");
                              setFieldValue("area_id", "");
                            } else {
                              setFieldValue("province_id", "");
                              setFieldValue("location_id", "");
                              setFieldValue("area_id", "");
                            }
                          }}
                          placeholder="Seleccione la provincia"
                          onBlur={handleBlur}
                          className={
                            errors.province_id && touched.province_id
                              ? "select-error"
                              : ""
                          }
                          isLoading={provinciesLoading}
                        />
                        {errors.province_id && touched.province_id && (
                          <FormFeedback className="d-block">
                            {errors.province_id}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="area_id">Localidad:</Label>
                        <VirtualizedSelect
                          id="area_id"
                          value={
                            areas
                              ? areas.find(
                                  area => area.value === values.area_id
                                )
                              : ""
                          }
                          options={areas}
                          onChange={async value => {
                            if (value) {
                              const id = value && value.value;
                              setFieldValue("area_id", id);
                              if (id) {
                                await getLocationById(id);
                              }
                            } else {
                              setFieldValue("area_id", "");
                              setFieldValue("location_id", "");
                            }
                          }}
                          placeholder="Seleccione la localidad"
                          onBlur={handleBlur}
                          className={
                            errors.area_id && touched.area_id
                              ? "select-error"
                              : ""
                          }
                          isLoading={areasLoading}
                        />
                        {errors.area_id && touched.area_id && (
                          <FormFeedback className="d-block">
                            {errors.area_id}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="location_id">Barrio:</Label>
                        <VirtualizedSelect
                          id="location_id"
                          value={
                            locations
                              ? locations.find(
                                  location =>
                                    location.value === values.location_id
                                )
                              : ""
                          }
                          options={locations}
                          onChange={async value => {
                            const id = value && value.value;
                            if (id) {
                              setFieldValue("location_id", id);
                            } else {
                              setFieldValue("location_id", "");
                            }
                          }}
                          placeholder="Seleccione el barrio"
                          onBlur={handleBlur}
                          className={
                            errors.location_id && touched.location_id
                              ? "select-error"
                              : ""
                          }
                          isLoading={locationsLoading}
                        />
                        {errors.location_id && touched.location_id && (
                          <FormFeedback className="d-block">
                            {errors.location_id}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      <FormGroup>
                        <Label for="postcode">Código Postal:</Label>
                        <Input
                          id="postcode"
                          type="text"
                          placeholder="Ingrese Código Postal"
                          value={values.postcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${errors.postcode &&
                            touched.postcode &&
                            "is-invalid"}`}
                        />
                        {errors.postcode && touched.postcode && (
                          <FormFeedback className="d-block">
                            {errors.postcode}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label for="phone">Teléfono:</Label>
                        <Input
                          id="phone"
                          type="text"
                          placeholder="Ingrese el número teléfonico"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`${errors.phone &&
                            touched.phone &&
                            "is-invalid"}`}
                        />
                        {errors.phone && touched.phone && (
                          <FormFeedback className="d-block">
                            {errors.phone}
                          </FormFeedback>
                        )}
                      </FormGroup>



                      <FormGroup>
                            <Label for="phone">Email:</Label>
                            <Input
                              id="email"
                              type="text"
                              placeholder="Eamil de la farmacia"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            
                            />
                         
                          </FormGroup>

                        
                          <FormGroup>
                            <Label for="manual">Borrar al sincronizar: &nbsp;&nbsp;&nbsp;&nbsp; </Label>


                            <Select
                                  id="manual"
                                  name="manual"
                                  onBlur={handleBlur}                                
                                  options={options}

                                  onChange={async value => {
                                     
                                    setFieldValue("manual", value.value);
                                  }}
                                  defaultValue={options[0]}

                                  className="basic-single"
                                  classNamePrefix="select"

                                />
                                
                                </FormGroup>





                      <FormGroup>
                        <PrimaryBtn type="submit" disabled={isSubmitting}>
                          {isSubmitting ? <Spinner size="sm" /> : "Guardar"}
                        </PrimaryBtn>
                      </FormGroup>
                    </fieldset>
                  </Form>
                )}
              />
            </ElementWrapper>
          </Col>
          <Col sm={6}>
            <Map center={latLong} zoom={15} />
          </Col>
        </Row>
      </Container>
    </PageTransition>
  );
};

PharmacyCreate.propTypes = {
  addPharmacy: PropTypes.func.isRequired,
  getProvincies: PropTypes.func.isRequired,
  provincies: PropTypes.array.isRequired,
  provinciesLoading: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  locationsLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  provincies: state.address.provincies.data,
  provinciesLoading: state.address.provincies.loading,
  locations: state.address.locations.data,
  locationsLoading: state.address.locations.loading,
  areas: state.address.areas.data,
  areasLoading: state.address.areas.loading
});

export default connect(
  mapStateToProps,
  {
    addPharmacy,
    getProvincies,
    getLocationById,
    getLocationInfoByName,
    getAreaById
  }
)(PharmacyCreate);
