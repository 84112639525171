import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getMETInfo } from "../../actions/medicalEntity";

import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import ElementWrapper from "../styles/ElementWrapper";

const FormTypesDetail = ({
  getMETInfo,
  match,
  history,
  loading,
  medicalEntityType
}) => {
  const fetchData = async () => {
    const response = await getMETInfo(match.params.id);

    if (response !== 200) {
      history.push("/medical_entity_type");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de Centro Médico" />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={6}>
              <ElementWrapper className="p-3 h-100">
                <div className="my-3">
                  <p className="m-0">Nombre: {medicalEntityType.description}</p>
                </div>
              </ElementWrapper>
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

const mapStateToProps = state => ({
  medicalEntityType: state.medicalEntityTypes.medicalEntity,
  loading: state.medicalEntityTypes.loading
});

export default connect(
  mapStateToProps,
  { getMETInfo }
)(FormTypesDetail);
