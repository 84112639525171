import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";
import ElementWrapper from "../styles/ElementWrapper";
import MemberListItem from "./MemberListItem";

const MemberList = ({ members }) => {
  if (members.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {members.map(member => (
          <MemberListItem
            key={member.id}
            name={member.full_name}
            email={member.email}
            id={member.id}
          />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

MemberList.propTypes = {
  members: PropTypes.array.isRequired
};

export default MemberList;
