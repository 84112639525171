export const MSERVICES_LOADING = "MSERVICES_LOADING";

export const GET_MSERVICES_SUCCESS = "GET_MSERVICES_SUCCESS";
export const GET_MSERVICES_FAILURE = "GET_MSERVICES_FAILURE";

export const SET_MSERVICES_SEARCH_QUERY = "SET_MSERVICES_SEARCH_QUERY";
export const SET_MSERVICES_PAGE = "SET_MSERVICES_PAGE";

export const GET_MSERVICE_DETAIL_SUCCESS = "GET_MSERVICE_DETAIL_SUCCESS";
export const GET_MSERVICE_DETAIL_FAILURE = "GET_MSERVICE_DETAIL_FAILURE";

export const RESET_MSERVICES_REDUCER = "RESET_MSERVICES_REDUCER";
