export const FORMS_LOADING = "FORMS_LOADING";

export const GET_FORMS_TYPES_SUCCESS = "GET_FORMS_TYPES_SUCCESS";
export const GET_FORMS_TYPES_FAILURE = "GET_FORMS_TYPES_FAILURE";

export const SET_FORM_TYPES_SEARCH_QUERY = "SET_FORM_TYPES_SEARCH_QUERY";
export const SET_FORM_TYPES_PAGE = "SET_FORM_TYPES_PAGE";

export const GET_FORMS_TYPE_INFO_SUCCESS = "GET_FORMS_TYPE_INFO_SUCCESS";
export const GET_FORMS_TYPE_INFO_FAILURE = "GET_FORMS_TYPE_INFO_FAILURE";

export const GET_FORMS_SUCCESS = "GET_FORMS_SUCCESS";
export const GET_FORMS_FAILURE = "GET_FORMS_FAILURE";

export const RESET_FORM_REDUCER = "RESET_FORM_REDUCER";

export const GET_FORM_INFO_SUCCESS = "GET_FORM_INFO_SUCCESS";
export const GET_FORM_INFO_FAILURE = "GET_FORM_INFO_FAILURE";
