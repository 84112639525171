import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { getProvinces, deleteProvince } from "../../actions/provinces";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const ProvincesItem = ({ province, deleteProvince, getProvinces, filters }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);
    setLocalLoader(true);

    const response = await deleteProvince(province.id);

    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }

    await getProvinces(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>{province.name}</td>
      <td>
        <TableItemActions
          pathName="provinces"
          id={province.id}
          toggle={toggle}
        />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading ? "Eliminando" : "Se eliminará esta provincia"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

ProvincesItem.propTypes = {
  province: PropTypes.object.isRequired,
  getProvinces: PropTypes.func.isRequired,
  deleteProvince: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filters: state.provinces.filters
});

export default connect(
  mapStateToProps,
  { getProvinces, deleteProvince }
)(ProvincesItem);
