import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTransition from "../styles/PageTransition";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";

import { connect } from "react-redux";
import { getUserInfo } from "../../actions/usersActions";

import PanelNavs from "../PanelNavs";
import ElementWrapper from "../styles/ElementWrapper";
import Loader from "../Loader";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import AvatarUpload from "./AvatarUpload";
import ProfileForm from "./ProfileForm";
import AccountForm from "./AccountForm";

const Profile = ({ getUserInfo, loading, user, history, authId, match }) => {
  const [tab, setTab] = useState(1);

  const fetchData = async () => {
    const response = await getUserInfo(match.params.id);

    if (response !== 200 || parseInt(match.params.id) !== authId) {
      history.push("/");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Mi cuenta" />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <ElementWrapper className="p-3">
            <Row className="align-items-center">
              <Col sm={12} md={8} className="order-1 order-sm-1 order-md-0">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 1 })}
                      onClick={() => setTab(1)}
                    >
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tab === 2 })}
                      onClick={() => setTab(2)}
                    >
                      Account
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={tab}>
                  <TabPane tabId={1}>
                    <div className="py-3">
                      <ProfileForm user={user} id={match.params.id} />
                    </div>
                  </TabPane>
                  <TabPane tabId={2}>
                    <div className="py-3">
                      <AccountForm id={match.params.id} />
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
              <Col sm={12} md={4} className="order-0 order-sm-0 order-md-1">
                <AvatarUpload avatar={user.avatar} id={match.params.id} />
              </Col>
            </Row>
          </ElementWrapper>
        )}
      </Container>
    </PageTransition>
  );
};
//   async componentDidMount() {
//     const { id } = this.props.match.params;
//     const { authId, history, getUserInfo } = this.props;
//     const paramId = parseInt(id);

//     if (paramId !== authId) {
//       history.push("/");
//       return;
//     }

//     await getUserInfo(id);
//   }

//   render() {
//     const { loading, user } = this.props;
//     const { id } = this.props.match.params;

//     return (

//     );
//   }
// }

Profile.propTypes = {
  authId: PropTypes.number.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authId: state.auth.user.id,
  user: state.users.user,
  loading: state.users.loading
});

export default connect(
  mapStateToProps,
  {
    getUserInfo
  }
)(Profile);
