import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";

// Components
import PharmacyListItem from "./PharmacyListItem";
import ElementWrapper from "../styles/ElementWrapper";

const PharmaciesList = ({ pharmacies }) => {
  if (pharmacies.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Dirección</th>
          <th>Localidad</th>
          <th>Barrio</th>
          <th>Teléfono</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {pharmacies.map((pharmacie, index) => (
          <PharmacyListItem key={pharmacie.id} {...pharmacie} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

PharmaciesList.propTypes = {
  pharmacies: PropTypes.array.isRequired
};

export default PharmaciesList;
