import axios from "axios";
import { API_URL } from "../api";
import {
  GET_CLINIC_TYPE_LOADING,
  GET_CLINICS_TYPES_SUCCESS,
  GET_CLINICS_TYPES_FAILURE
} from "./types/clinicsTypes";
import { SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { logout } from "./authActions";
import AlphabeticOrder from "../lib/AlphabeticOrder";

export const getClinicTypes = () => async dispatch => {
  try {
    dispatch({ type: GET_CLINIC_TYPE_LOADING });
    const response = await axios.get(
      `${API_URL}/api/admin/medical_entity_type?pagination=100`
    );

    dispatch({
      type: GET_CLINICS_TYPES_SUCCESS,
      payload: {
        clinicsTypes: AlphabeticOrder(response.data.data, "description").map(
          clinicType => ({
            value: clinicType.id,
            label: clinicType.description
          })
        )
      }
    });

    return response.status;
  } catch (error) {
    dispatch({
      type: GET_CLINICS_TYPES_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error al cargar los tipos de clínicas."]
      });
    }

    return error.response.status;
  }
};
