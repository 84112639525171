import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import VirtualizedSelect from "react-virtualized-select";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { connect } from "react-redux";
import {
  addClinic,
  getSpecialtiesForClinics
} from "../../actions/clinicsActions";
import {
  getProvincies,
  getLocationById,
  getLocationInfoByName,
  getAreaById
} from "../../actions/addressActions";
import { getMedicalServices } from "../../actions/mservices";
import { getClinicTypes } from "../../actions/clinicsTypesActions";
import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import PageTransition from "../styles/PageTransition";
import PrimaryBtn from "../PrimaryBtn";
import ContainerHeader from "../styles/ContainerHeader";
import Map from "../Map";

const ClinicCreate = ({
  loading,
  note,
  provincies,
  provinciesLoading,
  getProvincies,
  getLocationInfoByName,
  locationsLoading,
  locations,
  getLocationById,
  specialties,
  specialtiesLoading,
  getSpecialtiesForClinics,
  getClinicTypes,
  clinicTypes,
  clinicTypeLoading,
  history,
  addClinic,
  getAreaById,
  areas,
  areasLoading,
  getMedicalServices,
  mservices,
  mservicesLoading
}) => {
  const [latLong, setLatLong] = useState({
    lat: -34.6036844,
    lng: -58.381559100000004
  });

  useEffect(() => {
    const fetchProvinces = async () => {
      await Promise.all([
        getProvincies(),
        getSpecialtiesForClinics(),
        getClinicTypes(),
        getMedicalServices(null, true)
      ]);
    };

    fetchProvinces();
  }, []);

  const initialValues = {
    name: "",
    note: "",
    address: "",
    postcode: "",
    phone: "",
    province_id: "",
    location_id: "",
    medical_entity_type_id: "",
    specialties: [],
    lat: "",
    lon: "",
    area_id: "",
    medical_service_ids: [],
    address_extra: ""
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const response = await addClinic(values);

    if (response !== 200) {
      setSubmitting(false);
      return null;
    }

    setSubmitting(false);
    history.push(`/clinics`);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    address: Yup.string().required("Campo requerido"),
    phone: Yup.string().required("Campo requerido"),
    postcode: Yup.string().required("Campo requerido"),
    medical_entity_type_id: Yup.number().required("Campo requerido"),
    specialties: Yup.array().required("Campo requerido"),
    province_id: Yup.number()
      .required("Requerido")
      .nullable(),
    location_id: Yup.number()
      .required("Requerido")
      .nullable(),
    area_id: Yup.number()
      .required("Requerido")
      .nullable(),
    medical_service_ids: Yup.array().required("Requerido")
  });

  const render = ({
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue
  }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
          <FormGroup>
            <Label for="name">Nombre:</Label>
            <Input
              id="name"
              type="text"
              placeholder="Ingrese el nombre"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`${errors.name && touched.name && "is-invalid"}`}
            />
            {errors.name && touched.name && (
              <FormFeedback className="d-block">{errors.name}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="address">Dirección:</Label>
            <PlacesAutocomplete
              value={values.address}
              onChange={address => setFieldValue("address", address)}
              onSelect={async place => {
                setFieldValue("address", place);
                const results = await geocodeByAddress(place);
                const { lat, lng } = await getLatLng(results[0]);

                const comunaName = results[0].address_components.find(
                  item =>
                    item.types[0] === "administrative_area_level_2" ||
                    item.types[0] === "administrative_area_level_1"
                );

                const postalCode = results[0].address_components.find(
                  item => item.types[0] === "postal_code"
                );

                if (postalCode) {
                  setFieldValue("postcode", postalCode.long_name);
                } else {
                  setFieldValue("postcode", "");
                }

                const provinceId = await getLocationInfoByName(
                  comunaName.long_name
                );

                if (provinceId) {
                  // Set ProvinceID on the field
                  setFieldValue("province_id", provinceId);
                  // Get all Locations by provinceID
                  await Promise.all([
                    getLocationById(provinceId),
                    getAreaById(provinceId)
                  ]);
                } else {
                  setFieldValue("province_id", "");
                }

                setLatLong({
                  ...latLong,
                  lat,
                  lng
                });

                setFieldValue("lat", lat);
                setFieldValue("lon", lng);
              }}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
              }) => (
                <>
                  <input
                    {...getInputProps({
                      placeholder: "Buscar dirección...",
                      className: `form-control ${errors.address &&
                        touched.address &&
                        "is-invalid"}`
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && (
                      <div className="my-1 px-2">
                        <Spinner size="sm" />
                      </div>
                    )}
                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </PlacesAutocomplete>
            {errors.address && touched.address && (
              <FormFeedback className="d-block">{errors.address}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="province_id">Provincia:</Label>
            <VirtualizedSelect
              id="province_id"
              value={
                provincies
                  ? provincies.find(
                      province => province.value === values.province_id
                    )
                  : ""
              }
              options={provincies}
              onChange={async value => {
                if (value) {
                  const id = value.value;
                  setFieldValue("province_id", id);
                  await getAreaById(id);
                  setFieldValue("location_id", "");
                  setFieldValue("area_id", "");
                } else {
                  setFieldValue("province_id", "");
                  setFieldValue("location_id", "");
                  setFieldValue("area_id", "");
                }
              }}
              placeholder="Seleccione la provincia"
              onBlur={handleBlur}
              className={
                errors.province_id && touched.province_id ? "select-error" : ""
              }
              isLoading={provinciesLoading}
            />
            {errors.province_id && touched.province_id && (
              <FormFeedback className="d-block">
                {errors.province_id}
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="area_id">Localidad:</Label>
            <VirtualizedSelect
              id="area_id"
              value={
                areas ? areas.find(area => area.value === values.area_id) : ""
              }
              options={areas}
              onChange={async value => {
                if (value) {
                  const id = value && value.value;
                  setFieldValue("area_id", id);
                  if (id) {
                    await getLocationById(id);
                  }
                } else {
                  setFieldValue("area_id", "");
                  setFieldValue("location_id", "");
                }
              }}
              placeholder="Seleccione la localidad"
              onBlur={handleBlur}
              className={
                errors.area_id && touched.area_id ? "select-error" : ""
              }
              isLoading={areasLoading}
            />
            {errors.area_id && touched.area_id && (
              <FormFeedback className="d-block">{errors.area_id}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="location_id">Barrio:</Label>
            <VirtualizedSelect
              id="location_id"
              value={
                locations
                  ? locations.find(
                      location => location.value === values.location_id
                    )
                  : ""
              }
              options={locations}
              onChange={async value => {
                const id = value && value.value;
                if (id) {
                  setFieldValue("location_id", id);
                } else {
                  setFieldValue("location_id", "");
                }
              }}
              placeholder="Seleccione el barrio"
              onBlur={handleBlur}
              className={
                errors.location_id && touched.location_id ? "select-error" : ""
              }
              isLoading={locationsLoading}
            />
            {errors.location_id && touched.location_id && (
              <FormFeedback className="d-block">
                {errors.location_id}
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="address_extra">Piso y Dpto</Label>
            <Input
              id="address_extra"
              type="text"
              placeholder="Piso y Dpto"
              value={values.address_extra}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`${errors.address_extra &&
                touched.address_extra &&
                "is-invalid"}`}
            />
            {errors.address_extra && touched.address_extra && (
              <FormFeedback className="d-block">
                {errors.address_extra}
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="specialties">Especialidades Medicas:</Label>
            <VirtualizedSelect
              id="specialties"
              value={values.specialties}
              multi={true}
              options={specialties}
              onChange={async items => {
                setFieldValue("specialties", items);
              }}
              placeholder="Seleccione la especialidad"
              onBlur={handleBlur}
              className={
                errors.specialties && touched.specialties ? "select-error" : ""
              }
              isLoading={specialtiesLoading}
            />
            {errors.specialties && touched.specialties && (
              <FormFeedback className="d-block">
                {errors.specialties}
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="medical_service_ids">Servicio Médico:</Label>
            <VirtualizedSelect
              id="medical_service_ids"
              value={values.medical_service_ids}
              multi={true}
              options={mservices}
              onChange={async items => {
                setFieldValue("medical_service_ids", items);
              }}
              placeholder="Seleccione los servicios médicos"
              onBlur={handleBlur}
              className={
                errors.medical_service_ids && touched.medical_service_ids
                  ? "select-error"
                  : ""
              }
              isLoading={mservicesLoading}
            />
            {errors.medical_service_ids && touched.medical_service_ids && (
              <FormFeedback className="d-block">
                {errors.medical_service_ids}
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="clinicType">Tipo de Centro Médico:</Label>
            <VirtualizedSelect
              value={
                clinicTypes
                  ? clinicTypes.find(
                      clinictype =>
                        clinictype.value === values.medical_entity_type_id
                    )
                  : ""
              }
              options={clinicTypes}
              onChange={async value => {
                const id = value.value;
                setFieldValue("medical_entity_type_id", id);
              }}
              placeholder="Seleccione el Tipo de Centro Médico"
              className={
                errors.medical_entity_type_id && touched.medical_entity_type_id
                  ? "select-error"
                  : ""
              }
              isLoading={clinicTypeLoading}
            />
            {errors.medical_entity_type_id &&
              touched.medical_entity_type_id && (
                <FormFeedback className="d-block">
                  {errors.medical_entity_type_id}
                </FormFeedback>
              )}
          </FormGroup>

          <FormGroup>
            <Label for="postcode">Código Postal:</Label>
            <Input
              id="postcode"
              type="text"
              placeholder="Ingrese Código Postal"
              value={values.postcode}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`${errors.postcode &&
                touched.postcode &&
                "is-invalid"}`}
            />
            {errors.postcode && touched.postcode && (
              <FormFeedback className="d-block">{errors.postcode}</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for="phone">Teléfono:</Label>
            <Input
              id="phone"
              type="text"
              placeholder="Ingresa el número de teléfono"
              value={values.phone}
              onChange={handleChange}
              className={`${errors.phone && touched.phone && "is-invalid"}`}
            />
            {errors.phone && touched.phone && (
              <FormFeedback className="d-block">{errors.phone}</FormFeedback>
            )}
          </FormGroup>


          <FormGroup>
            <Label for="note">Notas:</Label>
              <textarea
                id="note"
                type="text"
                placeholder="Anotaciones"
                value={values.note}
                onChange={handleChange}
                onBlur={handleBlur}
                className={`${errors.note &&
                  touched.note &&
                  "is-invalid"} form-control`}
              />
              {errors.note && touched.note && (
                <FormFeedback className="d-block">
                  {errors.note}
                </FormFeedback>
              )}
          </FormGroup>


          <FormGroup>
            <PrimaryBtn type="submit" disabled={isSubmitting}>
              {isSubmitting ? <Spinner size="sm" /> : "Guardar"}
            </PrimaryBtn>
          </FormGroup>
        </fieldset>
      </Form>
    );
  };

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs loading={loading} title="Nuevo Centro Médico" />
        </ContainerHeader>
        <Row className="mb-5">
          <Col sm={6}>
            <ElementWrapper className="p-3">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                render={render}
              />
            </ElementWrapper>
          </Col>
          <Col sm={6}>
            <Map center={latLong} zoom={15} />
          </Col>
        </Row>
      </Container>
    </PageTransition>
  );
};

ClinicCreate.propTypes = {
  addClinic: PropTypes.func.isRequired,
  provincies: PropTypes.array.isRequired,
  provinciesLoading: PropTypes.bool.isRequired,
  locations: PropTypes.array.isRequired,
  locationsLoading: PropTypes.bool.isRequired,
  getClinicTypes: PropTypes.func.isRequired,
  clinicTypes: PropTypes.array.isRequired,
  clinicTypeLoading: PropTypes.bool.isRequired,
  specialties: PropTypes.array,
  specialtiesLoading: PropTypes.bool,
  getAreaById: PropTypes.func.isRequired,
  getMedicalServices: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  provincies: state.address.provincies.data,
  provinciesLoading: state.address.provincies.loading,
  locations: state.address.locations.data,
  locationsLoading: state.address.locations.loading,
  areas: state.address.areas.data,
  areasLoading: state.address.areas.loading,
  specialties: state.specialties.specialties,
  specialtiesLoading: state.specialties.loading,
  clinicTypes: state.clinic_type.clinicTypes,
  clinicTypeLoading: state.clinic_type.loading,
  loading: state.clinics.loading,
  mservices: state.mservices.services,
  mservicesLoading: state.mservices.loading
});

export default connect(mapStateToProps, {
  addClinic,
  getProvincies,
  getLocationById,
  getClinicTypes,
  getSpecialtiesForClinics,
  getLocationInfoByName,
  getAreaById,
  getMedicalServices
})(ClinicCreate);
