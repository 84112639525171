import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import {
  deleteSpecialty,
  getSpecialties
} from "../../actions/specialtiesActions";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const SpecialtiesListItem = ({
  description,
  filters,
  id,
  medical_entity,
  getSpecialties,
  deleteSpecialty
}) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);
    setLocalLoader(true);

    const response = await deleteSpecialty(id);

    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }

    await getSpecialties(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>{description}</td>
      <td>{medical_entity && medical_entity.length}</td>
      <td>
        <TableItemActions pathName="specialties" id={id} toggle={toggle} />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading ? "Eliminando" : "Se eliminará esta especialidad"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

SpecialtiesListItem.propTypes = {
  description: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  medical_entity: PropTypes.array.isRequired,
  getSpecialties: PropTypes.func.isRequired,
  deleteSpecialty: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filters: state.specialties.filters
});

export default connect(
  mapStateToProps,
  { deleteSpecialty, getSpecialties }
)(SpecialtiesListItem);
