import {
  MEMBERS_LOADING,
  GET_ALL_MEMBERS_SUCCESS,
  GET_ALL_MEMBERS_FAILURE,
  GET_SINGLE_MEMBER_SUCCESS,
  GET_SINGLE_MEMBER_FAILURE,
  SET_MEMBERS_SEARCH_QUERY,
  SET_MEMBERS_PAGE,
  RESET_MEMBERS_REDUCER
} from "../actions/types/membersTypes";

const initialState = {
  members: [],
  member: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    query: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBERS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_ALL_MEMBERS_SUCCESS: {
      return {
        ...state,
        members: action.payload.members,
        loading: false,
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_ALL_MEMBERS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_SINGLE_MEMBER_SUCCESS: {
      return {
        ...state,
        member: action.payload.member,
        loading: false
      };
    }

    case GET_SINGLE_MEMBER_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_MEMBERS_SEARCH_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_MEMBERS_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case RESET_MEMBERS_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
