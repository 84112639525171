import React from "react";
import {
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Spinner,
  InputGroup,
  InputGroupAddon,
  CustomInput
} from "reactstrap";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import { connect } from "react-redux";
import { addFormType } from "../../actions/forms";

import ElementWrapper from "../styles/ElementWrapper";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PrimaryBtn from "../PrimaryBtn";

const DynamicButton = styled.button`
  background: none;
  background-color: #dc3545;
  color: #fff;
  border: none;
  width: 50px;
`;

const FormTypesCreate = ({ addFormType, history }) => {
  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Nuevo tipo de formulario" />
        </ContainerHeader>
        <Row className="mb-5">
          <Col sm={6}>
            <ElementWrapper className="p-3">
              <Formik
                initialValues={{
                  name: "",
                  description: "",
                  emails: [],
                  loggedin: ""
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  const newValues = {
                    name: values.name,
                    description: values.description,
                    emails: values.emails,
                    loggedin: values.loggedin === "true" ? true : false
                  };

                  const response = await addFormType(newValues);

                  if (response !== 200) {
                    setSubmitting(false);
                    return;
                  }

                  setSubmitting(false);
                  history.push(`/form_types`);
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Requerido."),
                  description: Yup.string().required("Requerido."),
                  emails: Yup.array()
                    .min(1, "Debes agregar al menos 1 email.")
                    .required("Requerido"),
                  loggedin: Yup.string().required("Requerido.")
                })}
                render={({
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur
                }) => {
                  return (
                    <Form>
                      <fieldset
                        disabled={isSubmitting}
                        aria-busy={isSubmitting}
                      >
                        <FormGroup>
                          <Label for="name">Nombre:</Label>
                          <Input
                            id="name"
                            type="text"
                            placeholder="Ingrese el nombre"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${errors.name &&
                              touched.name &&
                              "is-invalid"}`}
                          />
                          {errors.name && touched.name && (
                            <FormFeedback className="d-block">
                              {errors.name}
                            </FormFeedback>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="description">Descripción:</Label>
                          <Input
                            id="description"
                            type="text"
                            placeholder="Ingrese una descripción"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${errors.description &&
                              touched.description &&
                              "is-invalid"}`}
                          />
                          {errors.description && touched.description && (
                            <FormFeedback className="d-block">
                              {errors.description}
                            </FormFeedback>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="description">Emails:</Label>

                          <FieldArray
                            name="emails"
                            render={arrayHelpers => (
                              <div>
                                {values.emails && values.emails.length > 0 ? (
                                  <>
                                    {values.emails.map((email, index) => (
                                      <InputGroup key={index} className="my-2">
                                        <Field
                                          type="email"
                                          name={`emails.${index}`}
                                          className="form-control"
                                          placeholder="Correo electrónico"
                                          required
                                        />
                                        <InputGroupAddon addonType="append">
                                          <DynamicButton
                                            type="button"
                                            onClick={() =>
                                              arrayHelpers.remove(index)
                                            }
                                          >
                                            &times;
                                          </DynamicButton>
                                        </InputGroupAddon>
                                      </InputGroup>
                                    ))}
                                    <div className="text-right">
                                      <PrimaryBtn
                                        type="button"
                                        onClick={() => arrayHelpers.push("")}
                                      >
                                        +
                                      </PrimaryBtn>
                                    </div>
                                  </>
                                ) : (
                                  <PrimaryBtn
                                    type="button"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    Agregar nuevo email
                                  </PrimaryBtn>
                                )}
                              </div>
                            )}
                          />

                          {errors.emails && touched.emails && (
                            <FormFeedback className="d-block">
                              {errors.emails}
                            </FormFeedback>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="loggedin">
                            Hay que ser afiliado para verlo
                          </Label>
                          <CustomInput
                            type="select"
                            id="loggedin"
                            name="loggedin"
                            value={values.loggedin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${errors.loggedin &&
                              touched.loggedin &&
                              "is-invalid"}`}
                          >
                            <option value="">Selecciona</option>
                            <option value="true">Si</option>
                            <option value="false">No</option>
                          </CustomInput>

                          {errors.loggedin && touched.loggedin && (
                            <FormFeedback className="d-block">
                              {errors.loggedin}
                            </FormFeedback>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <PrimaryBtn type="submit" disabled={isSubmitting}>
                            {isSubmitting ? <Spinner size="sm" /> : "Guardar"}
                          </PrimaryBtn>
                        </FormGroup>
                      </fieldset>
                    </Form>
                  );
                }}
              />
            </ElementWrapper>
          </Col>
        </Row>
      </Container>
    </PageTransition>
  );
};

export default connect(
  null,
  {
    addFormType
  }
)(FormTypesCreate);
