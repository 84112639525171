import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";
import ElementWrapper from "../styles/ElementWrapper";
import FormsItem from "./FormsItem";

const FormsList = ({ forms }) => {
  if (forms.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Motivo</th>
          <th>Nombre</th>
          <th>Email</th>
          <th>Teléfono</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {forms.map(form => (
          <FormsItem key={form.id} form={form} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

FormsList.propTypes = {
  forms: PropTypes.array.isRequired
};

export default FormsList;
