import axios from "axios";
import { API_URL } from "../api";
import {
  PHARMACY_LOADING,
  GET_ALL_PHARMACIES_SUCCESS,
  GET_ALL_PHARMACIES_FAILURE,
  GET_SINGLE_PHARMACY_SUCCESS,
  GET_SINGLE_PHARMACY_FAILURE,
  DELETE_PHARMACY_SUCCESS,
  DELETE_PHARMACY_FAILURE,
  SET_PHARMACIES_SEARCH_QUERY,
  SET_PAGE,
  RESET_PHARMACIES_REDUCER
} from "./types/pharmaciesTypes";
import { logout } from "./authActions";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";

export const getPharmacies = filters => async dispatch => {
  const { page, query } = filters;
  dispatch({ type: PHARMACY_LOADING });
  try {
    const endPoint = `${API_URL}/api/admin/pharmacy`;
    const pageParams = `?page=${page}&orderBy=name&orderByDir=asc`;

    let URL = `${endPoint}${pageParams}`;

    if (query.length > 0) {
      const searchParams = `&name=${query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_ALL_PHARMACIES_SUCCESS,
      payload: {
        pharmacies: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_ALL_PHARMACIES_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }
  }
};

export const getPharmacyInfo = id => async dispatch => {
  try {
    dispatch({ type: PHARMACY_LOADING });
    const response = await axios.get(`${API_URL}/api/admin/pharmacy/${id}`);

    dispatch({
      type: GET_SINGLE_PHARMACY_SUCCESS,
      payload: {
        pharmacy: response.data.pharmacy
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_SINGLE_PHARMACY_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const addPharmacy = pharmacy => async dispatch => {
  try {
    const response = await axios.post(
      `${API_URL}/api/admin/pharmacy`,
      pharmacy
    );

    dispatch({
      type: SHOW_ALERT_SUCCESS,
      message: [`Farmacia registrada satisfactoriamente.`]
    });

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updatePharmacy = (id, pharmacy) => async dispatch => {
  try {
    const response = await axios.put(
      `${API_URL}/api/admin/pharmacy/${id}`,
      pharmacy
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`${pharmacy.name} fue editada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deletePharmacy = id => async dispatch => {
  try {
    const response = await axios.delete(`${API_URL}/api/admin/pharmacy/${id}`);

    if (response.status === 204) {
      dispatch({
        type: DELETE_PHARMACY_SUCCESS,
        payload: {
          id
        }
      });

      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Registro eliminado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    dispatch({ type: DELETE_PHARMACY_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const setSearchQuery = query => ({
  type: SET_PHARMACIES_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_PAGE,
  payload: {
    value: page
  }
});

export const resetPharmacies = () => ({
  type: RESET_PHARMACIES_REDUCER
});
