import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import {
  getSpecialtyInfo,
  updateSpecialty
} from "../../actions/specialtiesActions";

import PanelNavs from "../PanelNavs";
import ElementWrapper from "../styles/ElementWrapper";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import PrimaryBtn from "../PrimaryBtn";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";

const SpecialtyUpdate = ({
  match,
  getSpecialtyInfo,
  loading,
  specialty,
  history,
  updateSpecialty
}) => {
  useEffect(() => {
    const fetchData = async () => await getSpecialtyInfo(match.params.id);

    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs loading={loading} title="Editar Especialidad Médica" />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row>
            <Col>
              <ElementWrapper className="p-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    description: specialty.description || ""
                  }}
                  validationSchema={Yup.object().shape({
                    description: Yup.string().required("Campo requerido.")
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    const response = await updateSpecialty(
                      match.params.id,
                      values
                    );

                    if (response !== 200) {
                      setSubmitting(false);
                      return;
                    }

                    setSubmitting(false);
                    history.push(`/specialties`);
                  }}
                  render={({
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <fieldset
                        disabled={isSubmitting}
                        aria-busy={isSubmitting}
                      >
                        <FormGroup>
                          <Label for="description">Nombre:</Label>
                          <Input
                            id="description"
                            type="text"
                            placeholder="Ingrese un nombre"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.description && touched.description && (
                            <FormFeedback className="d-block'">
                              {errors.description}
                            </FormFeedback>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <PrimaryBtn type="submit" disabled={isSubmitting}>
                            Editar
                          </PrimaryBtn>
                        </FormGroup>
                      </fieldset>
                    </Form>
                  )}
                />
              </ElementWrapper>
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

SpecialtyUpdate.propTypes = {
  getSpecialtyInfo: PropTypes.func.isRequired,
  updateSpecialty: PropTypes.func.isRequired,
  specialty: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  specialty: state.specialties.specialty,
  loading: state.specialties.loading
});

export default connect(
  mapStateToProps,
  { getSpecialtyInfo, updateSpecialty }
)(SpecialtyUpdate);
