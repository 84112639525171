import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getClinicInfo } from "../../actions/clinicsActions";

import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import Map from "../Map";

class ClinicSingle extends Component {
  async componentDidMount() {
    const { id } = this.props.match.params;
    const { getClinicInfo, history } = this.props;

    const response = await getClinicInfo(id);

    if (response !== 200) {
      history.push("/clinics");
    }
  }

  render() {
    const { loading, clinic } = this.props;

    return (
      <PageTransition>
        <Container>
          <ContainerHeader>
            <PanelNavs title="Detalle de Centro Médico" />
          </ContainerHeader>

          {loading ? (
            <LoaderWrapper>
              <Loader noBg={true} />
            </LoaderWrapper>
          ) : (
            <Row>
              <Col sm={6}>
                <ElementWrapper className="p-3 h-100">
                  <ul className="list-unstyled">
                    <li>
                      <p>Nombre: {clinic.name || "N/A"}</p>
                    </li>
                    <li>
                      <p>Dirección: {clinic.address || "N/A"}</p>
                    </li>
                    {clinic.location && (
                      <li>
                        <p>
                          Provincia:{" "}
                          {clinic.location.area.province.name || "N/A"}
                        </p>
                      </li>
                    )}
                    {clinic.area && (
                      <li>
                        <p>Localidad: {clinic.area.name || "N/A"}</p>
                      </li>
                    )}
                    {clinic.location && (
                      <li>
                        <p>Barrio: {clinic.location.name || "N/A"}</p>
                      </li>
                    )}
                    <li>
                      <p>Piso y Dpto: {clinic.address_extra || "N/A"}</p>
                    </li>
                    <li>
                      <p>Código Postal: {clinic.postcode || "N/A"}</p>
                    </li>
                    <li>
                      <p>Teléfono: {clinic.phone || "N/A"}</p>
                    </li>
                    <li className="mb-3">
                      <p>Especialidades Médicas:</p>
                      {clinic.medical_specialties && (
                        <ul>
                          {clinic.medical_specialties.map(item => (
                            <li key={item.value}>{item.label}</li>
                          ))}
                        </ul>
                      )}
                    </li>
                    <li className="mb-3">
                      <p>Servicios Médicos:</p>
                      {clinic.medical_services && (
                        <ul>
                          {clinic.medical_services.map(item => (
                            <li key={item.value}>{item.label}</li>
                          ))}
                        </ul>
                      )}
                    </li>
                    <li>
                      <p>
                        Tipo de Cartilla Médica:{" "}
                        {clinic.medical_entity_type
                          ? clinic.medical_entity_type.description
                          : "N/A"}
                      </p>
                    </li>

                    <li>
                      <p >Notas: <li className="parrafo">{clinic.note || "N/A"}</li></p>
                    </li>


                  </ul>
                </ElementWrapper>
              </Col>
              <Col sm={6}>
                <Map
                  center={{ lat: clinic.lat, lng: clinic.lon }}
                  zoom={15}
                  height={500}
                />
              </Col>
            </Row>
          )}
        </Container>
      </PageTransition>
    );
  }
}

const mapStateToProps = state => ({
  clinic: state.clinics.clinic,
  loading: state.clinics.loading
});

export default connect(mapStateToProps, { getClinicInfo })(ClinicSingle);
