import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import MembersContainer from "./MembersContainer";
import MemberSingle from "./MemberSingle";

const MembersRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={MembersContainer} />
      <Route exact path={`${match.path}/:id`} component={MemberSingle} />
    </Switch>
  </Fragment>
);

export default MembersRoutes;
