import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";

import ElementWrapper from "../styles/ElementWrapper";
import AreasItem from "./AreasItem";

const AreasList = ({ areas }) => {
  if (areas.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Provincia</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {areas.map(area => (
          <AreasItem key={area.id} area={area} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

AreasList.propTypes = {
  areas: PropTypes.array.isRequired
};

export default AreasList;
