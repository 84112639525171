import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import SpecialtiesContainer from "./SpecialtiesContainer";
import SpecialtySingle from "./SpecialtySingle";
import SpecialtyCreate from "./SpecialtyCreate";
import SpecialtyUpdate from "./SpecialtyUpdate";

const SpecialtiesRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={SpecialtiesContainer} />
      <Route path={`${match.path}/add`} component={SpecialtyCreate} />
      <Route path={`${match.path}/:id/edit`} component={SpecialtyUpdate} />
      <Route path={`${match.path}/:id`} component={SpecialtySingle} />
    </Switch>
  </Fragment>
);

export default SpecialtiesRoutes;
