import styled from "styled-components";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

export const DropdownOptionBtn = styled(DropdownItem)`
  display: flex !important;
  align-items: center;
  padding: 1em !important;
`;

export const DropdownOptionLink = styled(Link)`
  display: flex !important;
  align-items: center;
  padding: 1em !important;

  &:hover {
    background-color: ${props => props.theme.whiteSmoke};
  }
`;
