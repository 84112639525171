import styled from "styled-components";

const TableWrapper = styled.div`
  min-height: 300px;
  overflow-x: auto;

  table {
    td {
      display: table-cell;
      text-align: left;
      font-size: 12px;
      border-bottom: none;
    }
  }
`;

export default TableWrapper;
