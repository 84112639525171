import {
  CLINICS_LOADING,
  GET_ALL_CLINICS_SUCCESS,
  GET_ALL_CLINICS_FAILURE,
  DELETE_CLINIC_SUCCESS,
  DELETE_CLINIC_FAILURE,
  GET_SINGLE_CLINIC_SUCCESS,
  GET_SINGLE_CLINIC_FAILURE,
  GET_SPECIALTY_FOR_CLINIC_BY_ID_SUCCESS,
  GET_SPECIALTY_FOR_CLINIC_BY_ID_FAILURE,
  SET_CLINICS_SEARCH_QUERY,
  SET_CLINICS_PAGE,
  RESET_CLINICS_REDUCER
} from "../actions/types/clinicsTypes";

const initialState = {
  clinics: [],
  clinic: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    to: null,
    total: null
  },
  specialties: {
    loading: false,
    data: []
  },
  filters: {
    page: 1,
    query: ""
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLINICS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_ALL_CLINICS_SUCCESS: {
      return {
        ...state,
        clinics: action.payload.clinics,
        loading: false,
        links: {
          ...state.links,
          currentPage: action.payload.links.currentPage,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_ALL_CLINICS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_SINGLE_CLINIC_SUCCESS: {
      return {
        ...state,
        clinic: action.payload.clinic,
        loading: false
      };
    }

    case GET_SINGLE_CLINIC_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case DELETE_CLINIC_SUCCESS: {
      return {
        ...state,
        clinics: state.clinics.filter(
          clinic => clinic.id !== action.payload.id
        ),
        loading: false
      };
    }

    case DELETE_CLINIC_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_SPECIALTY_FOR_CLINIC_BY_ID_SUCCESS: {
      return {
        ...state,
        specialties: {
          ...state.specialties,
          loading: false
        },
        clinic: {
          ...state.clinic,
          specialties: action.payload.medicalSpecialties
        }
      };
    }

    case GET_SPECIALTY_FOR_CLINIC_BY_ID_FAILURE: {
      return {
        ...state,
        specialties: {
          ...state.specialties,
          loading: false
        }
      };
    }

    case SET_CLINICS_SEARCH_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_CLINICS_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case RESET_CLINICS_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
};
