import axios from "axios";
import { API_URL } from "../api";
import {
  USERS_LOADING,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
  UPDATE_IMAGE_LOADING,
  DELETE_IMAGE_COMPLETE_SUCCESS,
  DELETE_IMAGE_COMPLETE_FAILURE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
  SET_USERS_SEARCH_QUERY,
  SET_USERS_PAGE,
  SET_UPDATED_NAME_AUTH,
  RESET_USERS_REDUCER
} from "./types/usersTypes";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import _ from "lodash";
import { logout } from "./authActions";

export const getUsers = filters => async dispatch => {
  const { page, query } = filters;
  dispatch({ type: USERS_LOADING });

  try {
    const endPoint = `${API_URL}/api/admin/users`;
    const pageParams = `?page=${page}`;

    let URL = `${endPoint}${pageParams}`;

    if (query.length > 0) {
      const searchParams = `&name=${query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: {
        users: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_ALL_USERS_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getUserInfo = id => async dispatch => {
  try {
    dispatch({ type: USERS_LOADING });

    const response = await axios.get(`${API_URL}/api/admin/users/${id}`);

    if (_.isEmpty(response.data)) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: [`Ocurrió un error interno. Inténtelo más tarde.`]
      });
      return "error";
    }

    dispatch({
      type: GET_SINGLE_USER_SUCCESS,
      payload: {
        user: response.data.user
      }
    });

    return response.status;
  } catch (error) {
    dispatch({
      type: GET_SINGLE_USER_FAILURE
    });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const addUser = user => async dispatch => {
  try {
    const response = await axios.post(`${API_URL}/api/admin/users`, user);

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [
          `El usuario adminitrador, fue agregado satisfactoriamente.`
        ]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updateUser = (id, user) => async dispatch => {
  try {
    const response = await axios.put(`${API_URL}/api/admin/users/${id}`, user);

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Registro editado satisfactoriamente.`]
      });

      dispatch({
        type: SET_UPDATED_NAME_AUTH,
        payload: {
          value: response.data.user.name
        }
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deleteUser = id => async dispatch => {
  try {
    const response = await axios.delete(`${API_URL}/api/admin/users/${id}`);

    if (response.status === 204) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Registro eliminado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const uploadUserAvatar = (id, image) => async dispatch => {
  try {
    dispatch({ type: UPDATE_IMAGE_LOADING });

    const response = await axios.post(
      `${API_URL}/api/admin/users/${id}/avatar`,
      image
    );

    if (response.status === 200) {
      dispatch({
        type: UPLOAD_IMAGE_SUCCESS,
        payload: {
          avatar: response.data.user.avatar
        }
      });
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Avatar editado satisfactoriamente.`]
      });
    }
    return response.status;
  } catch (error) {
    dispatch({ type: UPLOAD_IMAGE_FAILURE });

    if (error.response && error.response.status === 422) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ErrorHandler(error.response.data.errors)
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error;
  }
};

export const deleteUserAvatar = id => async dispatch => {
  try {
    dispatch({ type: UPDATE_IMAGE_LOADING });

    const response = await axios.delete(
      `${API_URL}/api/admin/users/${id}/avatar`
    );

    if (response.status === 204) {
      dispatch({ type: DELETE_IMAGE_COMPLETE_SUCCESS });

      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Avatar eliminado satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    dispatch({ type: DELETE_IMAGE_COMPLETE_FAILURE });

    if (error.response && error.response.status === 422) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ErrorHandler(error.response.data.errors)
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error;
  }
};

export const setSearchQuery = query => ({
  type: SET_USERS_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_USERS_PAGE,
  payload: {
    value: page
  }
});

export const resetUsers = () => ({
  type: RESET_USERS_REDUCER
});
