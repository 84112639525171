import {
  FORMS_LOADING,
  GET_FORMS_TYPES_SUCCESS,
  GET_FORMS_TYPES_FAILURE,
  SET_FORM_TYPES_SEARCH_QUERY,
  SET_FORM_TYPES_PAGE,
  GET_FORMS_TYPE_INFO_SUCCESS,
  GET_FORMS_TYPE_INFO_FAILURE,
  GET_FORMS_SUCCESS,
  GET_FORMS_FAILURE,
  RESET_FORM_REDUCER,
  GET_FORM_INFO_SUCCESS,
  GET_FORM_INFO_FAILURE
} from "../actions/types/forms";

const initialState = {
  forms: [],
  form: {},
  loading: false,
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    from: null,
    to: null,
    total: null
  },
  filters: {
    page: 1,
    query: "",
    formTypeId: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FORMS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }

    case GET_FORMS_SUCCESS: {
      return {
        ...state,
        forms: action.payload.forms,
        loading: false,
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_FORMS_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case GET_FORMS_TYPES_SUCCESS: {
      return {
        ...state,
        forms: action.payload.forms,
        loading: false,
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          from: action.payload.meta.from,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case GET_FORMS_TYPES_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case SET_FORM_TYPES_SEARCH_QUERY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          query: action.payload.value,
          page: 1
        }
      };
    }

    case SET_FORM_TYPES_PAGE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          page: action.payload.value
        }
      };
    }

    case GET_FORMS_TYPE_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        form: action.payload.contactFormType
      };
    }

    case GET_FORMS_TYPE_INFO_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case RESET_FORM_REDUCER: {
      return {
        ...state,
        ...initialState
      };
    }

    case GET_FORM_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        form: action.payload.form
      };
    }

    case GET_FORM_INFO_FAILURE: {
      return {
        ...state,
        loading: false
      };
    }

    case "SET_FORM_TYPE_ID": {
      return {
        ...state,
        filters: {
          ...state.filters,
          formTypeId: action.payload.value
        }
      };
    }

    default: {
      return state;
    }
  }
};
