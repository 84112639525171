export const PROVINCES_LOADING = "PROVINCES_LOADING";

export const GET_PROVINCES_SUCCESS = "GET_PROVINCES_SUCCESS";
export const GET_PROVINCES_FAILURE = "GET_PROVINCES_FAILURE";

export const SET_PROVINCES_SEARCH_QUERY = "SET_PROVINCES_SEARCH_QUERY";
export const SET_PROVINCES_PAGE = "SET_PROVINCES_PAGE";

export const GET_PROVINCE_DETAIL_SUCCESS = "GET_PROVINCE_DETAIL_SUCCESS";
export const GET_PROVINCE_DETAIL_FAILURE = "GET_PROVINCE_DETAIL_FAILURE";

export const RESET_PROVINCES_REDUCER = "RESET_PROVINCES_REDUCER";
