import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import METContainer from "./METContainer";
import METCreate from "./METCreate";
import METEdit from "./METEdit";
import METDetail from "./METDetail";

const METRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={METContainer} />
      <Route path={`${match.path}/add`} component={METCreate} />
      <Route path={`${match.path}/:id/edit`} component={METEdit} />
      <Route path={`${match.path}/:id`} component={METDetail} />
    </Switch>
  </Fragment>
);

export default METRoutes;
