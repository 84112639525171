export const ADDRESS_LOADING = "ADDRESS_LOADING";
export const GET_PROVINCIES_LOADING = "GET_PROVINCIES_LOADING";
export const GET_LOCATIONS_LOADING = "GET_LOCATIONS_LOADING";
export const GET_AREAS_LOADING = "GET_AREAS_LOADING";

export const GET_SINGLE_ADDRESS_PROVINCE_SUCCESS =
  "GET_SINGLE_ADDRESS_PROVINCE_SUCCESS";
export const GET_SINGLE_ADDRESS_PROVINCE_FAILURE =
  "GET_SINGLE_ADDRESS_PROVINCE_SUCCESS";

export const GET_SINGLE_ADDRESS_LOCATION_SUCCESS =
  "GET_SINGLE_ADDRESS_LOCATION_SUCCESS";
export const GET_SINGLE_ADDRESS_LOCATION_FAILURE =
  "GET_SINGLE_ADDRESS_LOCATION_FAILURE";

export const GET_ADDRESS_PROVINCIES_SUCCESS = "GET_ADDRESS_PROVINCIES_SUCCESS";
export const GET_ADDRESS_PROVINCIES_FAILURE = "GET_ADDRESS_PROVINCIES_FAILURE";

export const GET_ADDRESS_LOCATIONS_SUCCESS = "GET_ADDRESS_LOCATIONS_SUCCESS";
export const GET_ADDRESS_LOCATIONS_FAILURE = "GET_ADDRESS_LOCATIONS_FAILURE";

export const GET_ADDRESS_AREAS_SUCCESS = "GET_ADDRESS_AREAS_SUCCESS";
export const GET_ADDRESS_AREAS_FAILURE = "GET_ADDRESS_AREAS_FAILURE";
