import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import { connect } from "react-redux";
import { getUsers, setSearchQuery, setPage } from "../../actions/usersActions";

// Components
import UsersList from "./UsersList";
import PageTransition from "../styles/PageTransition";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import Paginator from "../Paginator";
import PageMeta from "../PageMeta";
import PanelHead from "../PanelHead";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";

class UserContainer extends Component {
  searchField = createRef();

  handleSubmitData = async () => {
    await this.props.getUsers(this.props.filters);
  };

  componentDidMount() {
    this.handleSubmitData();
  }

  handleSearch = async query => {
    await this.props.setSearchQuery(query);
    this.handleSubmitData();
  };

  handleReload = async () => {
    this.searchField.current.state.query = "";
    await this.props.setSearchQuery("");
    this.handleSubmitData();
  };

  handlePaginator = async page => {
    await this.props.setPage(page);
    this.handleSubmitData();
  };

  render() {
    const {
      loading,
      meta,
      filters,
      first,
      last,
      next,
      prev,
      users
    } = this.props;

    return (
      <PageTransition>
        <Container>
          <fieldset disabled={loading} aria-busy={loading}>
            <ContainerHeader>
              <PanelNavs title="Administradores" url="/users/add" add={true} />
              <PanelHead
                handleSearch={this.handleSearch}
                handleReload={this.handleReload}
                refe={this.searchField}
                loading={loading}
              />
              <PageMeta {...meta} currentPage={filters.page} />
            </ContainerHeader>

            {loading ? (
              <LoaderWrapper>
                <Loader noBg={true} />
              </LoaderWrapper>
            ) : (
              <UsersList users={users} />
            )}

            {users.length > 0 && !loading && (
              <Paginator
                currentPage={filters.page}
                first={first}
                last={last}
                next={next}
                prev={prev}
                handlePaginator={this.handlePaginator}
              />
            )}
          </fieldset>
        </Container>
      </PageTransition>
    );
  }
}

UserContainer.propTypes = {
  users: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  first: PropTypes.string,
  last: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
  meta: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  users: state.users.users,
  loading: state.users.loading,
  filters: state.users.filters,
  first: state.users.links.first,
  last: state.users.links.last,
  next: state.users.links.next,
  prev: state.users.links.prev,
  meta: state.users.meta
});

export default connect(
  mapStateToProps,
  { getUsers, setSearchQuery, setPage }
)(UserContainer);
