import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { deleteClinic, getClinics } from "../../actions/clinicsActions";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const ClinicListItem = ({ clinic, deleteClinic, getClinics, filters }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);

    const response = await deleteClinic(clinic.id);

    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }

    await getClinics(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>
        <span className="text-truncate">{clinic.name}</span>
      </td>
      <td>{clinic.address}</td>
      <td>
        <span className="text-uppercase">
          {clinic.location.area ? clinic.location.area.name : "-"}
        </span>
      </td>
      <td>{clinic.location ? clinic.location.name : "-"}</td>
      <td>{clinic.phone || "-"}</td>
      <td>
        <TableItemActions pathName="clinics" id={clinic.id} toggle={toggle} />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading ? "Eliminando" : "Se eliminará este centro médico"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

ClinicListItem.propTypes = {
  clinic: PropTypes.object.isRequired,
  deleteClinic: PropTypes.func.isRequired,
  getClinics: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filters: state.clinics.filters
});

export default connect(
  mapStateToProps,
  { deleteClinic, getClinics }
)(ClinicListItem);
