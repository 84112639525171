import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import VirtualizedSelect from "react-virtualized-select";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { connect } from "react-redux";
import {
  getPharmacyInfo,
  updatePharmacy
} from "../../actions/pharmacieActions";
import {
  getProvincies,
  getLocationById,
  getLocationInfoByName,
  getAreaById
} from "../../actions/addressActions";
import PanelNavs from "../PanelNavs";
import ElementWrapper from "../styles/ElementWrapper";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import PrimaryBtn from "../PrimaryBtn";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import Map from "../Map";
import Select from "react-select";

const options = [
  { value: '0', label: 'No Borrar' },
  { value: '1', label: 'Borrar al sincronizar' },

]

class PharmacyUpdate extends Component {
  state = {
    latLng: {
      lat: null,
      lng: null
    },
    isLoading: false
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    const {
      getPharmacyInfo,
      getProvincies,
      getLocationById,
      history,
      getAreaById
    } = this.props;
    const response = await getPharmacyInfo(id);

    this.setState({
      ...this.state,
      latLng: {
        ...this.state.latLng,
        lat: this.props.pharmacy.lat,
        lng: this.props.pharmacy.lon
      },
      isLoading: true
    });

    if (response !== 200) {
      history.push("/pharmacies");
      return;
    }

    await getProvincies();

    if (this.props.pharmacy.location && this.props.pharmacy.location.area.id) {
      await getLocationById(this.props.pharmacy.location.area.id);
    }

    if (this.props.pharmacy.location && this.props.pharmacy.location.area.id) {
      await getAreaById(this.props.pharmacy.location.area.province.id);
    }

    this.setState({
      ...this.state,
      isLoading: false
    });
  }

  render() {
    const {
      loading,
      pharmacy,
      history,
      updatePharmacy,
      provincies,
      locations,
      locationsLoading,
      getLocationById,
      provinciesLoading,
      getLocationInfoByName,
      areas,
      areasLoading,
      getAreaById
      
    } = this.props;
    const { id } = this.props.match.params;
    const { isLoading } = this.state;

    const provinceId = pharmacy.location && pharmacy.location.area.province.id;
    const locationId = pharmacy.location && pharmacy.location.id;


   


    return (
      <PageTransition>
        <Container>
          <ContainerHeader>
            <PanelNavs
              loading={loading}
              title="Editar Farmacia"
              backUrl="/pharmacies"
            />
          </ContainerHeader>
          {loading ? (
            <LoaderWrapper>
              <Loader noBg={true} />
            </LoaderWrapper>
          ) : (
            <Row className="mb-5">
              <Col sm={6}>
                <ElementWrapper className="p-3">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: pharmacy.name || "",
                      address: pharmacy.address || "",
                      postcode: pharmacy.postcode || "",
                      phone: pharmacy.phone,
                      province_id: provinceId || "",
                      location_id: locationId || "",
                      lat: pharmacy.lat,
                      lon: pharmacy.lon,
                      email: pharmacy.email,
                      manual: pharmacy.manual,
                      area_id: pharmacy.location
                        ? pharmacy.location.area.id
                        : ""
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Requerido."),
                      address: Yup.string()
                        .min(
                          5,
                          "La dirección debe contener al menos 5 caracteres."
                        )
                        .required("Requerido"),
                      postcode: Yup.string().required("Requerido"),
                      province_id: Yup.number()
                        .required("Requerido")
                        .nullable(),
                      location_id: Yup.number()
                        .required("Requerido")
                        .nullable(),
                      phone: Yup.string().required("Requerido"),
                      area_id: Yup.number()
                        .required("Requerido")
                        .nullable()
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      const response = await updatePharmacy(id, values);

                      if (response !== 200) {
                        setSubmitting(false);
                        return null;
                      }

                      setSubmitting(false);
                      history.push(`/pharmacies`);
                    }}
                    render={({
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <fieldset
                          disabled={isSubmitting}
                          aria-busy={isSubmitting}
                        >
                          <FormGroup>
                            <Label for="name">Nombre:</Label>
                            <Input
                              id="name"
                              type="text"
                              placeholder="Ingrese el nombre"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name && (
                              <FormFeedback className="d-block">
                                {errors.name}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="address">Dirección:</Label>
                            <PlacesAutocomplete
                              value={values.address}
                              onChange={address =>
                                setFieldValue("address", address)
                              }
                              onSelect={async place => {
                                setFieldValue("address", place);
                                const results = await geocodeByAddress(place);
                                const { lat, lng } = await getLatLng(
                                  results[0]
                                );

                                setFieldValue("lat", lat);
                                setFieldValue("lon", lng);

                                this.setState({
                                  ...this.state,
                                  latLng: {
                                    ...this.state.latLng,
                                    lat,
                                    lng
                                  }
                                });

                                const postalCode = results[0].address_components.find(
                                  item => item.types[0] === "postal_code"
                                );

                                if (postalCode) {
                                  setFieldValue(
                                    "postcode",
                                    postalCode.long_name
                                  );
                                } else {
                                  setFieldValue("postcode", "");
                                }

                                const comunaName = results[0].address_components.find(
                                  item =>
                                    item.types[0] ===
                                      "administrative_area_level_2" ||
                                    item.types[0] ===
                                      "administrative_area_level_1"
                                );

                                const provinceId = await getLocationInfoByName(
                                  comunaName.long_name
                                );

                                if (provinceId) {
                                  // Set ProvinceID on the field
                                  setFieldValue("province_id", provinceId);
                                  // Get all Locations by provinceID
                                  await Promise.all([
                                    getAreaById(provinceId),
                                    getLocationById(provinceId)
                                  ]);
                                } else {
                                  setFieldValue("province_id", "");
                                }
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading
                              }) => (
                                <>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Buscar dirección...",
                                      className: `form-control ${errors.address &&
                                        touched.address &&
                                        "is-invalid"}`
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && (
                                      <div className="my-1 px-2">
                                        <Spinner size="sm" />
                                      </div>
                                    )}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </PlacesAutocomplete>
                            {errors.address && touched.address && (
                              <FormFeedback className="d-block">
                                {errors.address}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="province_id">Provincia:</Label>

                            <VirtualizedSelect
                              id="province_id"
                              value={
                                provincies
                                  ? provincies.find(
                                      province =>
                                        province.value === values.province_id
                                    )
                                  : ""
                              }
                              options={provincies}
                              onChange={async value => {
                                if (value) {
                                  const id = value.value;
                                  setFieldValue("province_id", id);
                                  await getAreaById(id);
                                  setFieldValue("location_id", "");
                                  setFieldValue("area_id", "");
                                } else {
                                  setFieldValue("province_id", "");
                                  setFieldValue("location_id", "");
                                  setFieldValue("area_id", "");
                                }
                              }}
                              onBlur={handleBlur}
                              isLoading={provinciesLoading || isLoading}
                              placeholder="Seleccione la provincia"
                            />
                            {errors.province_id && touched.province_id && (
                              <FormFeedback className="d-block">
                                {errors.province_id}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="area_id">Localidad:</Label>

                            <VirtualizedSelect
                              id="area_id"
                              value={
                                areas
                                  ? areas.find(
                                      area => area.value === values.area_id
                                    )
                                  : ""
                              }
                              options={areas}
                              onChange={async value => {
                                if (value) {
                                  const id = value && value.value;
                                  setFieldValue("area_id", id);
                                  if (id) {
                                    await getLocationById(id);
                                  }
                                } else {
                                  setFieldValue("area_id", "");
                                  setFieldValue("location_id", "");
                                }
                              }}
                              placeholder="Seleccione la localidad"
                              onBlur={handleBlur}
                              className={
                                errors.area_id && touched.area_id
                                  ? "select-error"
                                  : ""
                              }
                              isLoading={areasLoading || isLoading}
                            />
                            {errors.area_id && touched.area_id && (
                              <FormFeedback className="d-block">
                                {errors.area_id}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="location_id">Barrio:</Label>
                            <VirtualizedSelect
                              id="location_id"
                              value={
                                locations
                                  ? locations.find(
                                      location =>
                                        location.value === values.location_id
                                    )
                                  : ""
                              }
                              options={locations}
                              onChange={async value => {
                                const id = value && value.value;
                                if (id) {
                                  setFieldValue("location_id", id);
                                } else {
                                  setFieldValue("location_id", "");
                                }
                              }}
                              placeholder="Seleccione el barrio"
                              onBlur={handleBlur}
                              isLoading={locationsLoading || isLoading}
                            />
                            {errors.location_id && touched.location_id && (
                              <FormFeedback className="d-block">
                                {errors.location_id}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="postcode">Código Postal:</Label>
                            <Input
                              id="postcode"
                              type="text"
                              placeholder="Ingrese Código Postal"
                              value={values.postcode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.postcode && touched.postcode && (
                              <FormFeedback className="d-block">
                                {errors.postcode}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="phone">Teléfono:</Label>
                            <Input
                              id="phone"
                              type="text"
                              placeholder="Ingrese el número teléfonico"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phone && touched.phone && (
                              <FormFeedback className="d-block">
                                {errors.phone}
                              </FormFeedback>
                            )}
                          </FormGroup>


                          <FormGroup>
                            <Label for="phone">Email:</Label>
                            <Input
                              id="email"
                              type="text"
                              placeholder="Eamil de la farmacia"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            
                            />
                         
                          </FormGroup>

                        
                          <FormGroup>
                            <Label for="manual">Borrar al sincronizar: &nbsp;&nbsp;&nbsp;&nbsp; </Label>


                            <Select
                                  id="manual"
                                  name="manual"
                                  onBlur={handleBlur}                                
                                  options={options}

                                  onChange={async value => {
                                     
                                    setFieldValue("manual", value.value);
                                  }}
                                
                                  className="basic-single"
                                  classNamePrefix="select"
                                  defaultValue={options[values.manual]}

                                />
                                
                            
                          
                        
                          </FormGroup>



                          <FormGroup>
                            <PrimaryBtn
                              type="submit"
                              disabled={isSubmitting || isLoading}
                            >
                              {isSubmitting ? <Spinner size="sm" /> : "Editar"}
                            </PrimaryBtn>
                          </FormGroup>
                        </fieldset>
                      </Form>
                    )}
                  />
                </ElementWrapper>
              </Col>
              <Col sm={6}>
                <Map center={this.state.latLng} zoom={15} />
              </Col>
            </Row>
          )}
        </Container>
      </PageTransition>
    );
  }
}

PharmacyUpdate.propTypes = {
  getPharmacyInfo: PropTypes.func.isRequired,
  pharmacy: PropTypes.object.isRequired,
  updatePharmacy: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  provincies: PropTypes.array.isRequired,
  getProvincies: PropTypes.func.isRequired,
  getLocationById: PropTypes.func.isRequired,
  locationsLoading: PropTypes.bool.isRequired,
  provinciesLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  pharmacy: state.pharmacies.pharmacy,
  loading: state.pharmacies.loading,
  provincies: state.address.provincies.data,
  provinciesLoading: state.address.provincies.loading,
  locations: state.address.locations.data,
  locationsLoading: state.address.locations.loading,
  areas: state.address.areas.data,
  areasLoading: state.address.areas.loading
});

export default connect(mapStateToProps, {
  getPharmacyInfo,
  updatePharmacy,
  getProvincies,
  getLocationById,
  getLocationInfoByName,
  getAreaById
})(PharmacyUpdate);
