import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";

// Components
import LocationsItem from "./LocationsItem";
import ElementWrapper from "../styles/ElementWrapper";

const LocationsList = ({ locations }) => {
  if (locations.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Localidad</th>
          <th>Provincia</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {locations.map(location => (
          <LocationsItem key={location.id} location={location} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

LocationsList.propTypes = {
  locations: PropTypes.array.isRequired
};

export default LocationsList;
