import React from "react";
import { GridLoader } from "react-spinners";
import styled from "styled-components";

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.noBg ? "transparent" : "rgba(255, 255, 255, 1)"};
  z-index: 2;
`;

const Loader = ({ noBg }) => (
  <LoaderWrapper noBg={noBg}>
    <GridLoader sizeUnit={"px"} size={15} color={"#FC6435"} />
  </LoaderWrapper>
);

export default Loader;
