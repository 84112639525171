import React from "react";
import { Spring } from "react-spring/renderprops";

const LoginAnimation = ({ children }) => (
  <Spring
    from={{ opacity: 0, transform: "translateY(50px)" }}
    to={{ opacity: 1, transform: "translateY(0px)" }}
    delay={300}
  >
    {props => <div style={props}>{children}</div>}
  </Spring>
);

export default LoginAnimation;
