export const USERS_LOADING = "USERS_LOADING";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAILURE = "GET_SINGLE_USER_FAILURE";

export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILURE = "SEARCH_USERS_FAILURE";

export const UPDATE_IMAGE_LOADING = "UPDATE_IMAGE_LOADING";

export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const DELETE_IMAGE_COMPLETE_SUCCESS = "DELETE_IMAGE_COMPLETE_SUCCESS";
export const DELETE_IMAGE_COMPLETE_FAILURE = "DELETE_IMAGE_COMPLETE_FAILURE";

export const SET_USERS_SEARCH_QUERY = "SET_USERS_SEARCH_QUERY";
export const SET_USERS_PAGE = "SET_USERS_PAGE";

export const SET_UPDATED_NAME_AUTH = "SET_UPDATED_NAME_AUTH";

export const RESET_USERS_REDUCER = "RESET_USERS_REDUCER";
