import styled from "styled-components";

const ElementWrapper = styled.div`
  min-height: 300px;
  background-color: ${props => props.theme.whiteColor};
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  border-top: 4px solid ${props => props.theme.primaryColor};
`;

export default ElementWrapper;
