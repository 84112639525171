import React, { Component, createRef } from "react";
import { Container } from "reactstrap";

import { connect } from "react-redux";
import {
  getForms,
  setSearchQuery,
  setPage,
  setFormType
} from "../../actions/forms";

import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import PanelNavs from "../PanelNavs";
import PanelHead from "../PanelHead";
import PageMeta from "../PageMeta";
import LoaderWrapper from "../styles/LoaderWrapper";
import Loader from "../Loader";
import Paginator from "../Paginator";
import FormsList from "./FormsList";

class FormsContainer extends Component {
  searchField = createRef();

  handleSubmitData = async () => {
    await this.props.getForms(this.props.filters);
  };

  componentDidMount() {
    this.handleSubmitData();
  }

  handleSearch = async query => {
    await this.props.setSearchQuery(query);
    this.handleSubmitData();
  };

  handleReload = async () => {
    this.searchField.current.state.query = "";
    await this.props.setSearchQuery("");
    this.handleSubmitData();
  };

  handlePaginator = async page => {
    await this.props.setPage(page);
    this.handleSubmitData();
  };

  handleFormTypeFilter = async filter => {
    if (filter) {
      await this.props.setFormType(filter.value);
    } else {
      await this.props.setFormType(null);
    }
    this.handleSubmitData();
  };

  render() {
    const { meta, filters, loading, forms, links } = this.props;

    return (
      <PageTransition>
        <Container>
          <fieldset disabled={loading} aria-busy={loading}>
            <ContainerHeader>
              <PanelNavs
                title="Mensajes de Formularios"
                add={true}
                noBtn={true}
                url="/forms/add"
              />
              <PanelHead
                handleSearch={this.handleSearch}
                handleReload={this.handleReload}
                refe={this.searchField}
                loading={loading}
                formTypeFilter={true}
                handleFormTypeFilter={this.handleFormTypeFilter}
              />
              <PageMeta {...meta} currentPage={filters.page} />
            </ContainerHeader>

            {loading ? (
              <LoaderWrapper>
                <Loader noBg={true} />
              </LoaderWrapper>
            ) : (
              <FormsList forms={forms} />
            )}

            {forms.length > 0 && !loading && (
              <Paginator
                currentPage={filters.page}
                first={links.first}
                last={links.last}
                next={links.next}
                prev={links.prev}
                handlePaginator={this.handlePaginator}
              />
            )}
          </fieldset>
        </Container>
      </PageTransition>
    );
  }
}

const mapStateToProps = state => ({
  forms: state.forms.forms,
  loading: state.forms.loading,
  filters: state.forms.filters,
  meta: state.forms.meta,
  links: state.forms.links
});

export default connect(
  mapStateToProps,
  {
    getForms,
    setSearchQuery,
    setPage,
    setFormType
  }
)(FormsContainer);
