import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, FormGroup, Button } from "reactstrap";
import { FaSearch } from "react-icons/fa";
import styled from "styled-components";

const SearchButton = styled(Button)`
  background-color: ${props => props.theme.primaryColor} !important;
  border: 1px solid ${props => props.theme.primaryColor} !important;
`;

const SearchInput = styled(Input)`
  border: 1px solid ${props => props.theme.primaryColor} !important;
  border-right: none !important;
  border-radius: 0 !important;
`;

class Searcher extends Component {
  state = {
    query: ""
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.onSubmit(this.state.query);
  };

  render() {
    return (
      <Form inline onSubmit={this.handleSubmit}>
        <FormGroup className="m-0">
          <SearchInput
            type="text"
            name="query"
            placeholder="Buscar..."
            value={this.state.query}
            onChange={e => this.setState({ [e.target.name]: e.target.value })}
          />
        </FormGroup>
        <SearchButton>
          <FaSearch />
        </SearchButton>
      </Form>
    );
  }
}

Searcher.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default Searcher;
