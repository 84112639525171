import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import { connect } from "react-redux";
import {
  getClinics,
  setSearchQuery,
  setPage
} from "../../actions/clinicsActions";

import { downloadFile } from "../../actions/common";

import PageTransition from "../styles/PageTransition";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import Paginator from "../Paginator";
import PageMeta from "../PageMeta";
import PanelHead from "../PanelHead";
import ClinicsList from "./ClinicsList";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";

import { saveAs } from "file-saver";
import b64toBlob from "../../lib/base64ToXLS";




// COMMENT: ADD LINE
let btnEdit;


const storageRoot = JSON.parse(localStorage.getItem("persist:root")); 
  if (storageRoot){
    const json2 = JSON.parse(storageRoot.auth);

    const levelUser=json2.user.level;


    //basico
    if ( levelUser===0){
      btnEdit=0;
      //medio
    }else if(levelUser===1){
      btnEdit=1;
      //admin
    }else if (levelUser===2){
      btnEdit=2;
    }
    
//console.log ("level: "+levelUser+" isnoDelete: "+isnoDelete+" - isnoEdit: "+isnoEdit);
  }




class ClinicsContainer extends Component {
  searchField = React.createRef();
  state = {
    downloadLoading: false
  };

  handleSubmitData = async () => {
    const { filters, getClinics } = this.props;
    getClinics(filters);
  };

  componentDidMount() {
    this.handleSubmitData();
  }

  handleSearch = async query => {
    const { setSearchQuery } = this.props;
    await setSearchQuery(query);
    this.handleSubmitData();
  };

  handlePaginator = async page => {
    await this.props.setPage(page);
    this.handleSubmitData();
  };

  handleReload = async () => {
    this.searchField.current.state.query = "";
    await this.props.setSearchQuery("");
    this.handleSubmitData();
  };

  handleDownloadFile = async () => {
    this.setState({ downloadLoading: true });

    const response = await this.props.downloadFile("CartillaMedica");

    if (response.status === 200) {
      const blob = b64toBlob(response.data.file.content);

      // This function download the file
      saveAs(
        blob,
        `CartillaMedica_${Math.floor(
          100000000 + Math.random() * 900000000
        )}.xls`
      );
    }

    this.setState({ downloadLoading: false });
  };

  render() {
    const {
      loading,
      clinics,
      first,
      last,
      next,
      prev,
      meta,
      filters
    } = this.props;

    return (
      <PageTransition>
        <Container>
          <fieldset disabled={loading} aria-busy={loading}>
            <ContainerHeader>

            {btnEdit ===0 ? (
            <b ></b>
          ) : (
              <PanelNavs
                title="Centros Médicos"
                add={true}
                url="/clinics/add"
              />
          )}


              <PanelHead
                handleSearch={this.handleSearch}
                handleReload={this.handleReload}
                refe={this.searchField}
                handleDownloadFile={this.handleDownloadFile}
                loadingDownload={this.state.downloadLoading}
              />
              <PageMeta {...meta} currentPage={filters.page} />
            </ContainerHeader>

            {loading ? (
              <LoaderWrapper>
                <Loader noBg={true} />
              </LoaderWrapper>
            ) : (
              <ClinicsList clinics={clinics} />
            )}

            {clinics.length > 0 && !loading && (
              <Paginator
                currentPage={filters.page}
                first={first}
                last={last}
                next={next}
                prev={prev}
                handlePaginator={this.handlePaginator}
              />
            )}
          </fieldset>
        </Container>
      </PageTransition>
    );
  }
}

ClinicsContainer.propTypes = {
  clinics: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  first: PropTypes.string,
  last: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
  meta: PropTypes.object,
  filters: PropTypes.object
};

const mapStateToProps = state => ({
  clinics: state.clinics.clinics,
  loading: state.clinics.loading,
  first: state.clinics.links.first,
  last: state.clinics.links.last,
  next: state.clinics.links.next,
  prev: state.clinics.links.prev,
  meta: state.clinics.meta,
  filters: state.clinics.filters
});

export default connect(mapStateToProps, {
  getClinics,
  setSearchQuery,
  setPage,
  downloadFile
})(ClinicsContainer);
