import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";
import { getMService } from "../../actions/mservices";

import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";
import ElementWrapper from "../styles/ElementWrapper";

const MServicesDetail = ({ getMService, match, history, loading, service }) => {
  const fetchData = async () => {
    const response = await getMService(match.params.id);

    if (response !== 200) {
      history.push("/locations");
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de Servicio Médico" loading={loading} />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col sm={6}>
              <ElementWrapper className="p-3 h-100">
                <div className="my-3">
                  <p className="m-0">Nombre: {service.description}</p>
                </div>
              </ElementWrapper>
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

MServicesDetail.propTypes = {
  getMService: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  service: state.mservices.service,
  loading: state.mservices.loading
});

export default connect(
  mapStateToProps,
  { getMService }
)(MServicesDetail);
