import styled from "styled-components";

const HeaderWrapper = styled.header`
  min-height: 20vh;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default HeaderWrapper;
