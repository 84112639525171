import axios from "axios";
import { setToken } from "../api";
import { LOGIN_USER_SUCCESS, LOGOUT_USER_SUCCESS } from "./types/authTypes";
import { SHOW_ALERT_ERROR } from "./types/alertTypes";
import { API_URL } from "../api";
import ErrorHandler from "../lib/ErrorHandler";

export const login = data => async dispatch => {
  try {
    const response = await axios.post(`${API_URL}/api/admin/login`, data);

    const userInfo = response.data.user;
    const token = response.data.access_token;
    localStorage.setItem("jwtTokenAS", token);
    setToken(token);

    dispatch({
      type: LOGIN_USER_SUCCESS,
      payload: {
        userInfo: userInfo,
        isAuth: true
      }
      
    });

    return response.status;

    
  } catch (error) {
    if (error.response && error.response.status === 422) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ErrorHandler(error.response.data.errors)
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Email o Contraseña incorrecta."]
      });
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const logout = () => async dispatch => {
  localStorage.removeItem("jwtTokenAS");
  setToken(false);

  dispatch({
    type: LOGOUT_USER_SUCCESS,
    payload: {
      userInfo: {},
      isAuth: false
    }
  });
};
