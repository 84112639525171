import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import { connect } from "react-redux";
import {
  getMembers,
  setSearchQuery,
  setPage
} from "../../actions/membersActions";

import PageTransition from "../styles/PageTransition";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import Paginator from "../Paginator";
import PageMeta from "../PageMeta";
import PanelHead from "../PanelHead";
import MemberList from "./MemberList";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";

class MembersContainer extends Component {
  searchField = createRef();

  handleSubmitData = async () => {
    await this.props.getMembers(this.props.filters);
  };

  componentDidMount() {
    this.handleSubmitData();
  }

  handleSearch = async query => {
    await this.props.setSearchQuery(query);
    this.handleSubmitData();
  };

  handleReload = async () => {
    this.searchField.current.state.query = "";
    await this.props.setSearchQuery("");
    this.handleSubmitData();
  };

  handlePaginator = async page => {
    await this.props.setPage(page);
    this.handleSubmitData();
  };

  render() {
    const {
      meta,
      filters,
      loading,
      first,
      last,
      next,
      prev,
      members
    } = this.props;

    return (
      <PageTransition>
        <Container>
          <fieldset disabled={loading} aria-busy={loading}>
            <ContainerHeader>
              <PanelNavs title="Afiliados" add={true} noBtn={true} />
              <PanelHead
                handleSearch={this.handleSearch}
                handleReload={this.handleReload}
                refe={this.searchField}
              />
              <PageMeta {...meta} currentPage={filters.page} />
            </ContainerHeader>

            {loading ? (
              <LoaderWrapper>
                <Loader noBg={true} />
              </LoaderWrapper>
            ) : (
              <MemberList members={members} />
            )}

            {members.length > 0 && !loading && (
              <Paginator
                currentPage={filters.page}
                first={first}
                last={last}
                next={next}
                prev={prev}
                handlePaginator={this.handlePaginator}
              />
            )}
          </fieldset>
        </Container>
      </PageTransition>
    );
  }
}

MembersContainer.propTypes = {
  members: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  first: PropTypes.string,
  last: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
  meta: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  getMembers: PropTypes.func.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  members: state.members.members,
  loading: state.members.loading,
  first: state.members.links.first,
  last: state.members.links.last,
  next: state.members.links.next,
  prev: state.members.links.prev,
  meta: state.members.meta,
  filters: state.members.filters
});

export default connect(
  mapStateToProps,
  { getMembers, setSearchQuery, setPage }
)(MembersContainer);
