import React from "react";
import { Row, Col, Table } from "reactstrap";

import ElementWrapper from "./styles/ElementWrapper";
import TableWrapper from "./styles/TableWrapper";

const PanelListWrapper = ({ children }) => (
  <Row>
    <Col>
      <ElementWrapper>
        <TableWrapper>
          <Table hover>{children}</Table>
        </TableWrapper>
      </ElementWrapper>
    </Col>
  </Row>
);

export default PanelListWrapper;
