import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Spinner
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import VirtualizedSelect from "react-virtualized-select";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import Map from "../Map";

import { connect } from "react-redux";
import {
  getClinicInfo,
  updateClinic,
  getSpecialtiesForClinics,
  getSpecialtiesForClinicById
} from "../../actions/clinicsActions";
import {
  getProvincies,
  getLocationById,
  getLocationInfoByName,
  getAreaById
} from "../../actions/addressActions";
import { getClinicTypes } from "../../actions/clinicsTypesActions";
import { getMedicalServices } from "../../actions/mservices";
import PanelNavs from "../PanelNavs";
import ElementWrapper from "../styles/ElementWrapper";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import PrimaryBtn from "../PrimaryBtn";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";

class ClinicUpdate extends Component {
  state = {
    latLng: {
      lat: null,
      lng: null
    },
    isLoading: false
  };

  async componentDidMount() {
    const {
      getClinicInfo,
      match,
      history,
      getProvincies,
      getLocationById,
      getSpecialtiesForClinics,
      getClinicTypes,
      getAreaById,
      getMedicalServices
    } = this.props;

    const response = await getClinicInfo(match.params.id);

    this.setState({
      ...this.state,
      latLng: {
        ...this.state.latLng,
        lat: this.props.clinic.lat,
        lng: this.props.clinic.lon
      },
      isLoading: true
    });

    if (response !== 200) {
      history.push("/clinics");
      return;
    }

    await Promise.all([
      getProvincies(),
      getSpecialtiesForClinics(),
      getClinicTypes(),
      getMedicalServices(null, true)
    ]);

    if (this.props.clinic.location && this.props.clinic.location.area.id) {
      await getLocationById(this.props.clinic.location.area.id);
    }

    if (this.props.clinic.location && this.props.clinic.location.area.id) {
      await getAreaById(this.props.clinic.location.area.province.id);
    }

    this.setState({
      ...this.state,
      isLoading: false
    });
  }

  render() {
    const {
      loading,
      clinic,
      getLocationInfoByName,
      getLocationById,
      provinciesLoading,
      provincies,
      locations,
      locationsLoading,
      specialties,
      specialtiesLoading,
      clinicTypes,
      clinicTypeLoading,
      updateClinic,
      history,
      areas,
      areasLoading,
      getAreaById,
      mservices,
      mservicesLoading
    } = this.props;

    const { isLoading } = this.state;

    return (
      <PageTransition>
        <Container>
          <ContainerHeader>
            <PanelNavs title="Editar Centro Médico" />
          </ContainerHeader>
          {loading ? (
            <LoaderWrapper>
              <Loader noBg={true} />
            </LoaderWrapper>
          ) : (
            <Row className="mb-5">
              <Col sm={6}>
                <ElementWrapper className="p-3">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      name: clinic.name || "",
                      note: clinic.note || "",
                      postcode: clinic.postcode || "",
                      phone: clinic.phone || "",
                      address: clinic.address || "",
                      province_id: clinic.location
                        ? clinic.location.area.province.id
                        : "",
                      location_id: clinic.location ? clinic.location.id : "",
                      medical_entity_type_id: clinic.medical_entity_type
                        ? clinic.medical_entity_type.id
                        : "",
                      specialties: clinic.medical_specialties || [],
                      lat: clinic.lat || "",
                      lon: clinic.lon || "",
                      area_id: clinic.location ? clinic.location.area.id : "",
                      medical_service_ids: clinic.medical_services || [],
                      address_extra: clinic.address_extra || "",
                      note: clinic.note || ""
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      const { id } = this.props.match.params;
                      const response = await updateClinic(id, values);

                      if (response !== 200) {
                        setSubmitting(false);
                        return null;
                      }

                      setSubmitting(false);
                      history.push(`/clinics`);
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Campo requerido."),
                      address: Yup.string().required("Campo requerido"),
                      phone: Yup.string().required("Campo requerido"),
                      postcode: Yup.string().required("Campo requerido"),
                      medical_entity_type_id: Yup.number().required(
                        "Campo requerido"
                      ),
                      specialties: Yup.array().required("Campo requerido"),
                      province_id: Yup.number()
                        .required("Requerido")
                        .nullable(),
                      location_id: Yup.number()
                        .required("Requerido")
                        .nullable(),
                      area_id: Yup.number()
                        .required("Requerido")
                        .nullable()
                    })}
                    render={({
                      values,
                      touched,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <fieldset
                          disabled={isSubmitting}
                          aria-busy={isSubmitting}
                        >
                          <FormGroup>
                            <Input
                              id="name"
                              type="text"
                              placeholder="Ingrese el nombre"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${errors.name &&
                                touched.name &&
                                "is-invalid"}`}
                            />
                            {errors.name && touched.name && (
                              <FormFeedback className="d-block">
                                {errors.name}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="address">Dirección:</Label>
                            <PlacesAutocomplete
                              value={values.address}
                              onChange={address =>
                                setFieldValue("address", address)
                              }
                              onSelect={async place => {
                                setFieldValue("address", place);
                                const results = await geocodeByAddress(place);
                                const { lat, lng } = await getLatLng(
                                  results[0]
                                );

                                setFieldValue("lat", lat);
                                setFieldValue("lon", lng);

                                this.setState({
                                  ...this.state,
                                  latLng: {
                                    ...this.state.latLng,
                                    lat,
                                    lng
                                  }
                                });

                                const postalCode = results[0].address_components.find(
                                  item => item.types[0] === "postal_code"
                                );

                                if (postalCode) {
                                  setFieldValue(
                                    "postcode",
                                    postalCode.long_name
                                  );
                                } else {
                                  setFieldValue("postcode", "");
                                }

                                const comunaName = results[0].address_components.find(
                                  item =>
                                    item.types[0] ===
                                      "administrative_area_level_2" ||
                                    item.types[0] ===
                                      "administrative_area_level_1"
                                );

                                const provinceId = await getLocationInfoByName(
                                  comunaName.long_name
                                );

                                if (provinceId) {
                                  setFieldValue("province_id", provinceId);
                                  await Promise.all([
                                    getLocationById(provinceId),
                                    getAreaById(provinceId)
                                  ]);
                                } else {
                                  setFieldValue("province_id", "");
                                }
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading
                              }) => (
                                <>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Buscar dirección...",
                                      className: `form-control ${errors.address &&
                                        touched.address &&
                                        "is-invalid"}`
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && (
                                      <div className="my-1 px-2">
                                        <Spinner size="sm" />
                                      </div>
                                    )}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}
                            </PlacesAutocomplete>
                            {errors.address && touched.address && (
                              <FormFeedback className="d-block">
                                {errors.address}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="province_id">Provincia:</Label>
                            <VirtualizedSelect
                              id="province_id"
                              value={
                                provincies
                                  ? provincies.find(
                                      province =>
                                        province.value === values.province_id
                                    )
                                  : ""
                              }
                              options={provincies}
                              onChange={async value => {
                                if (value) {
                                  const id = value.value;
                                  setFieldValue("province_id", id);
                                  await getAreaById(id);
                                  setFieldValue("location_id", "");
                                  setFieldValue("area_id", "");
                                } else {
                                  setFieldValue("province_id", "");
                                  setFieldValue("location_id", "");
                                  setFieldValue("area_id", "");
                                }
                              }}
                              onBlur={handleBlur}
                              isLoading={provinciesLoading}
                            />
                            {errors.province_id && touched.province_id && (
                              <FormFeedback className="d-block">
                                {errors.province_id}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="area_id">Localidad:</Label>
                            <VirtualizedSelect
                              id="area_id"
                              value={
                                areas
                                  ? areas.find(
                                      area => area.value === values.area_id
                                    )
                                  : ""
                              }
                              options={areas}
                              onChange={async value => {
                                if (value) {
                                  const id = value && value.value;
                                  setFieldValue("area_id", id);
                                  if (id) {
                                    await getLocationById(id);
                                  }
                                } else {
                                  setFieldValue("area_id", "");
                                  setFieldValue("location_id", "");
                                }
                              }}
                              placeholder="Seleccione la localidad"
                              onBlur={handleBlur}
                              className={
                                errors.area_id && touched.area_id
                                  ? "select-error"
                                  : ""
                              }
                              isLoading={areasLoading || isLoading}
                            />
                            {errors.area_id && touched.area_id && (
                              <FormFeedback className="d-block">
                                {errors.area_id}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="location_id">Barrio:</Label>
                            <VirtualizedSelect
                              id="location_id"
                              value={
                                locations
                                  ? locations.find(
                                      location =>
                                        location.value === values.location_id
                                    )
                                  : ""
                              }
                              options={locations}
                              onChange={async value => {
                                const id = value && value.value;
                                if (id) {
                                  setFieldValue("location_id", id);
                                } else {
                                  setFieldValue("location_id", "");
                                }
                              }}
                              placeholder="Seleccione la localidad"
                              onBlur={handleBlur}
                              isLoading={
                                (values.province_id && locationsLoading) ||
                                isLoading
                              }
                            />
                            {errors.location_id && touched.location_id && (
                              <FormFeedback className="d-block">
                                {errors.location_id}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="address_extra">Piso y Dpto</Label>
                            <Input
                              id="address_extra"
                              type="text"
                              placeholder="Piso y Dpto"
                              value={values.address_extra}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${errors.address_extra &&
                                touched.address_extra &&
                                "is-invalid"}`}
                            />
                            {errors.address_extra && touched.address_extra && (
                              <FormFeedback className="d-block">
                                {errors.address_extra}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="specialties">
                              Especialidades Médicas:
                            </Label>
                            <VirtualizedSelect
                              id="specialties"
                              value={values.specialties}
                              multi={true}
                              options={specialties}
                              onChange={async items => {
                                setFieldValue("specialties", items);
                              }}
                              placeholder="Seleccione la especialidad"
                              onBlur={handleBlur}
                              className={
                                errors.specialties && touched.specialties
                                  ? "select-error"
                                  : ""
                              }
                              isLoading={specialtiesLoading}
                            />
                            {errors.specialties && touched.specialties && (
                              <FormFeedback className="d-block">
                                {errors.specialties}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="clinicType">
                              Tipo de Centro Médico:
                            </Label>
                            <VirtualizedSelect
                              value={
                                clinicTypes
                                  ? clinicTypes.find(
                                      clinictype =>
                                        clinictype.value ===
                                        values.medical_entity_type_id
                                    )
                                  : ""
                              }
                              options={clinicTypes}
                              onChange={async value => {
                                const id = value.value;
                                setFieldValue("medical_entity_type_id", id);
                              }}
                              placeholder="Seleccione el Tipo de Centro Médico"
                              className={
                                errors.medical_entity_type_id &&
                                touched.medical_entity_type_id
                                  ? "select-error"
                                  : ""
                              }
                              isLoading={clinicTypeLoading}
                            />
                            {errors.medical_entity_type_id &&
                              touched.medical_entity_type_id && (
                                <FormFeedback className="d-block">
                                  {errors.medical_entity_type_id}
                                </FormFeedback>
                              )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="medical_service_ids">
                              Servicios Médicos:
                            </Label>
                            <VirtualizedSelect
                              id="medical_service_ids"
                              value={values.medical_service_ids}
                              multi={true}
                              options={mservices}
                              onChange={async items => {
                                setFieldValue("medical_service_ids", items);
                              }}
                              placeholder="Seleccione los servicios médicos"
                              onBlur={handleBlur}
                              className={
                                errors.medical_service_ids &&
                                touched.medical_service_ids
                                  ? "select-error"
                                  : ""
                              }
                              isLoading={mservicesLoading}
                            />
                            {errors.medical_service_ids &&
                              touched.medical_service_ids && (
                                <FormFeedback className="d-block">
                                  {errors.medical_service_ids}
                                </FormFeedback>
                              )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="postcode">Código Postal:</Label>
                            <Input
                              id="postcode"
                              type="text"
                              placeholder="Ingrese Código Postal"
                              value={values.postcode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${errors.postcode &&
                                touched.postcode &&
                                "is-invalid"}`}
                            />
                            {errors.postcode && touched.postcode && (
                              <FormFeedback className="d-block">
                                {errors.postcode}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                            <Label for="phone">Teléfono:</Label>
                            <Input
                              id="phone"
                              type="text"
                              placeholder="Ingresa el número de teléfono"
                              value={values.phone}
                              onChange={handleChange}
                              className={`${errors.phone &&
                                touched.phone &&
                                "is-invalid"}`}
                            />
                            {errors.phone && touched.phone && (
                              <FormFeedback className="d-block">
                                {errors.phone}
                              </FormFeedback>
                            )}
                          </FormGroup>

                          <FormGroup>
                          <Label for="note">Notas:</Label>
                            <textarea
                              id="note"
                              type="text"
                              placeholder="Anotaciones"
                              value={values.note}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className={`${errors.note &&
                                touched.note &&
                                "is-invalid"} form-control`}
                            />
                            {errors.note && touched.note && (
                              <FormFeedback className="d-block">
                                {errors.note}
                              </FormFeedback>
                            )}
                          </FormGroup>


                          <FormGroup>
                            <PrimaryBtn
                              type="submit"
                              disabled={isSubmitting || isLoading}
                            >
                              {isSubmitting ? <Spinner size="sm" /> : "Editar"}
                            </PrimaryBtn>
                          </FormGroup>
                        </fieldset>
                      </Form>
                    )}
                  />
                </ElementWrapper>
              </Col>
              <Col sm={6}>
                <Map center={this.state.latLng} zoom={15} />
              </Col>
            </Row>
          )}
        </Container>
      </PageTransition>
    );
  }
}

const mapStateToProps = state => ({
  note: state.clinics.note,
  clinic: state.clinics.clinic,
  loading: state.clinics.loading,
  provincies: state.address.provincies.data,
  provinciesLoading: state.address.provincies.loading,
  locations: state.address.locations.data,
  locationsLoading: state.address.locations.loading,
  clinicTypes: state.clinic_type.clinicTypes,
  clinicTypeLoading: state.clinic_type.loading,
  specialties: state.specialties.specialties,
  specialtiesLoading: state.specialties.loading,
  areas: state.address.areas.data,
  areasLoading: state.address.areas.loading,
  mservices: state.mservices.services,
  mservicesLoading: state.mservices.loading
});

export default connect(mapStateToProps, {
  getClinicInfo,
  getProvincies,
  getLocationById,
  updateClinic,
  getClinicTypes,
  getSpecialtiesForClinics,
  getSpecialtiesForClinicById,
  getLocationInfoByName,
  getAreaById,
  getMedicalServices
})(ClinicUpdate);
