import React from "react";
import { Spring } from "react-spring/renderprops";

const LoginAnimation = ({ children }) => (
  <Spring from={{ opacity: 0 }} to={{ opacity: 1 }} delay={200}>
    {props => <div style={props}>{children}</div>}
  </Spring>
);

export default LoginAnimation;
