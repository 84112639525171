import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { getAreas, deleteArea } from "../../actions/areas";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const AreasItem = ({ area, filters, getAreas, deleteArea }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);
    setLocalLoader(true);

    const response = await deleteArea(area.id);

    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }

    await getAreas(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>{area.name}</td>
      <td>{area.province && area.province.name}</td>
      <td>
        <TableItemActions pathName="areas" id={area.id} toggle={toggle} />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading ? "Eliminando" : "Se eliminará esta localidad"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

AreasItem.propTypes = {
  area: PropTypes.object.isRequired,
  getAreas: PropTypes.func.isRequired,
  deleteArea: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filters: state.areas.filters
});

export default connect(
  mapStateToProps,
  {
    getAreas,
    deleteArea
  }
)(AreasItem);
