export const PHARMACY_LOADING = "PHARMACY_LOADING";

export const GET_ALL_PHARMACIES_SUCCESS = "GET_ALL_PHARMACIES_SUCCESS";
export const GET_ALL_PHARMACIES_FAILURE = "GET_ALL_PHARMACIES_FAILURE";

export const GET_SINGLE_PHARMACY_SUCCESS = "GET_SINGLE_PHARMACY_SUCCESS";
export const GET_SINGLE_PHARMACY_FAILURE = "GET_SINGLE_PHARMACY_FAILURE";

export const SET_PHARMACIES_SEARCH_QUERY = "SET_PHARMACIES_SEARCH_QUERY";

export const DELETE_PHARMACY_SUCCESS = "DELETE_PHARMACY_SUCCESS";
export const DELETE_PHARMACY_FAILURE = "DELETE_PHARMACY_FAILURE";

export const SET_PAGE = "SET_PAGE";

export const RESET_PHARMACIES_REDUCER = "RESET_PHARMACIES_REDUCER";
