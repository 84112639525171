import React, { useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { FaTimes, FaCamera } from "react-icons/fa";

import { connect } from "react-redux";
import { uploadUserAvatar, deleteUserAvatar } from "../../actions/usersActions";

const AvatarWrapper = styled.div`
  position: relative;
  max-width: 200px;
  margin: 0 auto;
  overflow: hidden;
`;

const AvatarEmpty = styled.div`
  height: 200px;
  background-color: #dfe6e9;
  border-radius: 50%;
  border: 3px dotted #b2bec3;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #b2bec3;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

const AvatarDeleteBTN = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background-color: #e74c3c;
  border: none;
  color: #fbfbfb;
  z-index: 2;
  width: 35px;
  height: 35px;
`;

const AvatarImage = styled.img`
  border-radius: 50%;
  width: 200px !important;
  height: 200px !important;
  position: relative;
  z-index: 1;
  object-fit: cover;
`;

const AvatarUploadBTN = styled.fieldset`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;

  label {
    cursor: pointer;
    margin: 0;
    padding: 0.5em 1em;
    background-color: ${props => props.theme.primaryColor};
    color: ${props => props.theme.whiteColor};
    border-radius: 10px;
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

const AvatarUpload = ({
  avatar,
  uploadUserAvatar,
  id,
  loading,
  deleteUserAvatar
}) => {
  const [preview, setPreview] = useState(avatar);

  const handleChange = event => {
    event.preventDefault();
    let file = event.target.files[0];
    let previewReader = new FileReader();
    const formData = new FormData();
    formData.append("avatar", file);

    if (!file) {
      return;
    }

    previewReader.onloadend = async () => {
      const response = await uploadUserAvatar(id, formData);

      if (response === 200) {
        setPreview(previewReader.result);
      }
    };

    previewReader.readAsDataURL(file);
  };

  return (
    <AvatarWrapper>
      <AvatarDeleteBTN
        onClick={async () => {
          const response = await deleteUserAvatar(id);
          if (response === 204) {
            setPreview(null);
          }
        }}
        disabled={loading}
      >
        {loading ? <Spinner size="sm" color="light" /> : <FaTimes />}
      </AvatarDeleteBTN>
      {!avatar && !preview ? (
        <AvatarEmpty>
          <span>Avatar</span>
        </AvatarEmpty>
      ) : (
        <AvatarImage src={preview} />
      )}
      <AvatarUploadBTN disabled={loading} aria-busy={loading}>
        <label htmlFor="avatar">
          {loading ? <Spinner size="sm" color="light" /> : <FaCamera />}
        </label>
        <input id="avatar" type="file" onChange={handleChange} />
      </AvatarUploadBTN>
    </AvatarWrapper>
  );
};

AvatarUpload.propTypes = {
  avatar: PropTypes.string,
  deleteUserAvatar: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  uploadUserAvatar: PropTypes.func.isRequired
};

const mapStateToprops = state => ({
  loading: state.users.uploadImageLoading
});

export default connect(
  mapStateToprops,
  {
    uploadUserAvatar,
    deleteUserAvatar
  }
)(AvatarUpload);
