export const AREAS_LOADING = "AREAS_LOADING";

export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";
export const GET_AREAS_FAILURE = "GET_AREAS_FAILURE";

export const SET_AREAS_SEARCH = "SET_AREAS_SEARCH";
export const SET_AREAS_PAGE = "SET_AREAS_PAGE";

export const GET_AREA_INFO_SUCCESS = "GET_AREA_INFO_SUCCESS";
export const GET_AREA_INFO_FAILURE = "GET_AREA_INFO_FAILURE";

export const RESET_AREAS_REDUCER = "RESET_AREAS_REDUCER";
