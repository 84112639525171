import React, { useState } from "react";
import { Collapse, Card, CardBody } from "reactstrap";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import styled from "styled-components";

const SpecialtyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const TogglerButton = styled.button`
  border: none;
  background: none;

  &:focus {
    outline: none;
  }
`;

const Collapsable = ({ title, children }) => {
  const [collapse, setCollapse] = useState(false);

  const toggler = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      <SpecialtyWrapper onClick={toggler}>
        <div>
          <strong>{title || "N/A"}</strong>
        </div>
        <div>
          <TogglerButton>
            {collapse ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </TogglerButton>
        </div>
      </SpecialtyWrapper>
      <Collapse isOpen={collapse}>
        <Card style={{ borderColor: "transparent" }}>
          <CardBody>{children}</CardBody>
        </Card>
      </Collapse>
    </>
  );
};

export default Collapsable;
