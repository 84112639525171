import { DropdownToggle } from "reactstrap";
import styled from "styled-components";

const ActionBtn = styled(DropdownToggle)`
  background-color: transparent !important;
  border: none !important;
  box-shadow: 0 0 0 0.2rem transparent !important;

  svg {
    color: ${props => props.theme.primaryColor};
  }
`;

export default ActionBtn;
