export default (data, fieldName) =>
  data.sort((a, b) => a[fieldName].localeCompare(b[fieldName]));
//   users.
//   return data.sort(function(a, b) {
//     if (a[fieldName] < b[fieldName]) {
//       return -1;
//     }
//     if (a[fieldName] > b[fieldName]) {
//       return 1;
//     }
//     return 0;
//   });
// };
