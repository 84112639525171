import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  // @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,900');

  body {
    // font-family: 'Montserrat', sans-serif !important;
    background-color: #FBFBFB !important;
    overflow-x: hidden;
  }

  a, a:hover {
    text-decoration: none !important;
  }

  button.dropdown-item.active, button.dropdown-item:active {
    background-color: #f8f9fa;
  }

  .navbar-light .navbar-nav a.nav-link,
  .navbar-light .navbar-nav a.nav-link:hover,
  .navbar-light .navbar-nav .show>.nav-link {
    color: ${props => props.theme.primaryColor} !important;
  }

  .btn {
    border-radius: 0 !important;
  }

  ol.breadcrumb {
    margin: 0;
    background-color: #fff;
    border-radius: 0;

    li {
      a {
        color: ${props => props.theme.primaryColor};

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .dropdown-menu {
    border-radius: 0 !important;
  }

  ul.pagination {
    margin: 0 !important;
    justify-content: center;
  }

  button.btn-primary {
    background-color: ${props => props.theme.primaryColor} !important;
    border: none;
    padding: .4em 3em;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12) !important;
    transition: all 200ms ease;
    

    &:focys {
      box-shadow: 0 0 0 0.2rem transparent !important;
    }

    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15) !important;
    }
  }

  .VirtualizedDanger {
    border-color: #dc3545 !important;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
  }
`;

export default GlobalStyles;
