import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { getFormsTypes, deleteFormType } from "../../actions/forms";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const ServicesItem = ({ form, getFormsTypes, filters, deleteFormType }) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);
    setLocalLoader(true);

    const response = await deleteFormType(form.id);

    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }

    await getFormsTypes(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>{form.name}</td>
      <td>{form.description}</td>
      <td>
        <TableItemActions
          pathName="form_types"
          id={form.id}
          toggle={toggle}
          noDelete
        />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading ? "Eliminando" : "Se eliminará este tipo de formulario"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

ServicesItem.propTypes = {
  form: PropTypes.object.isRequired,
  getFormsTypes: PropTypes.func.isRequired,
  deleteFormType: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filters: state.forms.filters
});

export default connect(
  mapStateToProps,
  { getFormsTypes, deleteFormType }
)(ServicesItem);
