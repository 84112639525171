import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";
import ElementWrapper from "../styles/ElementWrapper";
import SpecialtiesListItem from "./SpecialtiesListItem";

const SpecialtiesList = ({ specialties }) => {
  if (specialties.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Centros Médicos</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {specialties.map(specialty => (
          <SpecialtiesListItem key={specialty.id} {...specialty} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

SpecialtiesList.propTypes = {
  specialties: PropTypes.array.isRequired
};

export default SpecialtiesList;
