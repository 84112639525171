import { combineReducers } from "redux";
import pharmacies from "./pharmacies";
import auth from "./auth";
import togglers from "./togglerReducer";
import alert from "./alert";
import users from "./users";
import members from "./members";
import clinics from "./clinics";
import address from "./address";
import clinic_type from "./clinic_type";
import specialties from "./specialties";
import provinces from "./provinces";
import locations from "./locations";
import mservices from "./mservices";
import forms from "./forms";
import areas from "./areas";
import medicalEntityTypes from "./medicalEntityTypes";

export default combineReducers({
  auth,
  pharmacies,
  togglers,
  alert,
  users,
  members,
  clinics,
  address,
  clinic_type,
  specialties,
  provinces,
  locations,
  mservices,
  forms,
  areas,
  medicalEntityTypes
});
