import React from "react";
import PropTypes from "prop-types";
import PanelListWrapper from "../PanelListWrapper";
import ElementWrapper from "../styles/ElementWrapper";
import ClinicListItem from "./ClinicListItem";

const ClinicsList = ({ clinics }) => {
  if (clinics.length === 0) {
    return (
      <ElementWrapper className="p-3">
        <h1>Lo sentimos, no encontramos nada</h1>
      </ElementWrapper>
    );
  }

  return (
    <PanelListWrapper>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Dirección</th>
          <th>Localidad</th>
          <th>Barrio</th>
          <th>Teléfono</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {clinics.map(clinic => (
          <ClinicListItem key={clinic.id} clinic={clinic} />
        ))}
      </tbody>
    </PanelListWrapper>
  );
};

ClinicsList.propTypes = {
  clinics: PropTypes.array.isRequired
};

export default ClinicsList;
