import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import Collapsable from "../Collapsable";
import Moment from "react-moment";

import { connect } from "react-redux";
import { getMemberInfo } from "../../actions/membersActions";

import ElementWrapper from "../styles/ElementWrapper";
import PanelNavs from "../PanelNavs";
import Loader from "../Loader";
import PageTransition from "../styles/PageTransition";
import ContainerHeader from "../styles/ContainerHeader";
import LoaderWrapper from "../styles/LoaderWrapper";

const MemberSingle = ({ getMemberInfo, match, member, loading }) => {
  useEffect(() => {
    const getData = async () => {
      await getMemberInfo(match.params.id);
    };

    getData();
  }, []);

  return (
    <PageTransition>
      <Container>
        <ContainerHeader>
          <PanelNavs title="Detalle de Afiliado" />
        </ContainerHeader>
        {loading ? (
          <LoaderWrapper>
            <Loader noBg={true} />
          </LoaderWrapper>
        ) : (
          <Row className="mb-5">
            <Col>
              <ElementWrapper className="p-3">
                <ul className="list-unstyled">
                  <li>
                    <p>Nombre: {member.full_name || "N/A"}</p>
                  </li>
                  <li>
                    <p>
                      Documento: {member.personal_id_type || "N/A"} -
                      {member.personal_id || "N/A"}
                    </p>
                  </li>
                  <li>
                    <p>
                      Número de Miembro: {member.membership_number || "N/A"}
                    </p>
                  </li>
                  <li>
                    <p>
                      Fecha de nacimiento:{" "}
                      <Moment date={member.born_date} format="LL" />
                    </p>
                  </li>
                  <li>
                    <p>Email: {member.email || "N/A"}</p>
                  </li>
                  <li>
                    <p>Dirección: {member.address || "N/A"}</p>
                  </li>
                  <li>
                    <p>Celular: {member.cellphone || "N/A"}</p>
                  </li>
                  <li>
                    <p>Teléfono Fijo: {member.phone || "N/A"}</p>
                  </li>
                  <li>
                    <p>Historia: {member.historia || "N/A"}</p>
                  </li>
                  <li>
                    <p>
                      Estudios:{" "}
                      {member.estudios ? (
                        <a
                          href={member.estudios.link}
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          Entrar aquí
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </li>
                  <li>
                    <p>Turnos: </p>
                    <ListGroup>
                      {member.turnos &&
                        member.turnos.map(turno => (
                          <ListGroupItem key={turno.id}>
                            <Collapsable title={`No. ${turno.id}`}>
                              <p>Especialidad: {turno.especialidad}</p>
                              <p>
                                Fecha:{" "}
                                <Moment date={turno.fecha} format="LLL" />
                              </p>
                              <p>Medico: {turno.medico}</p>
                            </Collapsable>
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </li>
                </ul>
              </ElementWrapper>
            </Col>
          </Row>
        )}
      </Container>
    </PageTransition>
  );
};

MemberSingle.propTypes = {
  member: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  getMemberInfo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  member: state.members.member,
  loading: state.members.loading
});

export default connect(
  mapStateToProps,
  { getMemberInfo }
)(MemberSingle);
