import React, { useState } from "react";
import { Collapse, Card, CardBody } from "reactstrap";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import styled from "styled-components";

const SpecialtyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const TogglerButton = styled.button`
  border: none;
  background: none;

  &:focus {
    outline: none;
  }
`;

const SpecialtyCollapse = ({
  name,
  address,
  phone,
  postcode,
  province,
  location,
  area
}) => {
  const [collapse, setCollapse] = useState(false);

  const toggler = () => {
    setCollapse(!collapse);
  };

  return (
    <>
      <SpecialtyWrapper onClick={toggler}>
        <div>
          <strong>{name || "N/A"}</strong>
        </div>
        <div>
          <TogglerButton>
            {collapse ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
          </TogglerButton>
        </div>
      </SpecialtyWrapper>
      <Collapse isOpen={collapse}>
        <Card style={{ borderColor: "transparent" }}>
          <CardBody>
            <p>Dirección: {address || "N/A"}</p>
            <p>Provincia: {province.name || "N/A"}</p>
            <p>Localidad: {area.name || "N/A"}</p>
            <p>Barrio: {location.name || "N/A"}</p>
            <p>Teléfono: {phone || "N/A"}</p>
            <p>Código Postal: {postcode || "N/A"}</p>
          </CardBody>
        </Card>
      </Collapse>
    </>
  );
};

export default SpecialtyCollapse;
