import React from "react";
import PropTypes from "prop-types";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const MemberListItem = ({ id, name, email }) => (
  <TableRow>
    <td>{name}</td>
    <td>{email}</td>
    <td>
      <TableItemActions
        pathName="affiliates"
        id={id}
        noDelete={true}
        noEdit={true}
      />
    </td>
  </TableRow>
);

MemberListItem.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};

export default MemberListItem;
