import { TOGGLER_SIDEBAR } from "../actions/types/themeTypes";

const initialState = {
  togglerSidebar: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLER_SIDEBAR:
      return {
        ...state,
        togglerSidebar: !state.togglerSidebar
      };
    default:
      return state;
  }
};
