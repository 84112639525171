import React, { Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { closeAlert } from "../actions/alertActions";

const GlobalAlert = ({ closeAlert, show, type, message }) => {
  const handleClose = () => {
    closeAlert();
  };

  return (
    <Fragment>
      {show && type === "success" && (
        <SweetAlert
          success
          title="Solicitud procesada exitosamente."
          onConfirm={handleClose}
          confirmBtnBsStyle="primary"
        >
          {message.map((m, i) => (
            <Alert key={i} color="success">
              {m}
            </Alert>
          ))}
        </SweetAlert>
      )}

      {show && type === "error" && (
        <SweetAlert danger title="¡Ocurrio un error!" onConfirm={handleClose}>
          {message.map((m, i) => (
            <Alert key={i} color="danger">
              {m}
            </Alert>
          ))}
        </SweetAlert>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  show: state.alert.show,
  type: state.alert.type,
  message: state.alert.message
});

export default connect(
  mapStateToProps,
  { closeAlert }
)(GlobalAlert);
