const siteConfig = {
  theme: {
    primaryColor: "#FC6435",
    secundaryColor: "#2E3742",
    whiteColor: "#fff",
    whiteSmoke: "whitesmoke",
    blackColor: "#000",
    transWhite: "rgba(255,255,255,.2)",
    transBlack: "rgba(0,0,0,.2)",
    grayColor: "#686868",
    breakpoints: {
      xs: "576px",
      s: "768px",
      m: "992px",
      l: "1200px"
    }
  },
  API_URL: process.env.REACT_APP_API
};

export default siteConfig;
