import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { deletePharmacy, getPharmacies } from "../../actions/pharmacieActions";

import TableItemActions from "../TableItemActions";
import TableRow from "../styles/TableRow";

const PharmacyListItem = ({
  name,
  address,
  location,
  phone,
  id,
  deletePharmacy,
  getPharmacies,
  filters
}) => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localLoader, setLocalLoader] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleDelete = async () => {
    setLoading(true);
    setLocalLoader(true);

    const response = await deletePharmacy(id);

    if (response !== 204) {
      setLocalLoader(false);
      setLoading(false);
      setModal(false);
      return;
    }

    await getPharmacies(filters);
  };

  return (
    <TableRow localLoader={localLoader}>
      <td>{name}</td>
      <td>{address}</td>
      <td>
        <span className="text-uppercase">
          {location ? location.area && location.area.name : "-"}
        </span>
      </td>
      <td>{location ? location.name : "-"}</td>
      <td>{phone}</td>
      <td>
        <TableItemActions pathName="pharmacies" id={id} toggle={toggle} />
        <Modal isOpen={modal} centered>
          <ModalBody>
            <h3 className="text-center">
              {loading ? "Eliminando" : "Se eliminará esta farmacia?"}
            </h3>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner className="custom-spinner" />
            ) : (
              <>
                <Button color="secondary" onClick={toggle}>
                  Cancelar
                </Button>
                <Button color="danger" onClick={handleDelete}>
                  Borrar
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </td>
    </TableRow>
  );
};

PharmacyListItem.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  location: PropTypes.object,
  province: PropTypes.object,
  phone: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  getPharmacies: PropTypes.func.isRequired,
  deletePharmacy: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  filters: state.pharmacies.filters
});

export default connect(
  mapStateToProps,
  { deletePharmacy, getPharmacies }
)(PharmacyListItem);
