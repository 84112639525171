import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import UserContainer from "./UserContainer";
import UserCreate from "./UserCreate";
import UserSingle from "./UserSingle";

const UsersRoutes = ({ match }) => (
  <Fragment>
    <Switch>
      <Route exact path={match.path} component={UserContainer} />
      <Route path={`${match.path}/add`} component={UserCreate} />
      <Route path={`${match.path}/:id`} component={UserSingle} />
    </Switch>
  </Fragment>
);

export default UsersRoutes;
