import styled from "styled-components";

const PageTitle = styled.h1`
  margin: 0;
  font-weight: 900;
  text-transform: uppercase;

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    font-size: 1.1em;
  }

  @media (min-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.5em;
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    font-size: 2em;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    font-size: 2.5em;
  }
`;

export default PageTitle;
