import axios from "axios";
import { API_URL } from "../api";
import {
  LOCATIONS_LOADING,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
  SET_LOCATIONS_SEARCH_QUERY,
  SET_LOCATIONS_PAGE,
  GET_LOCATION_DETAIL_SUCCESS,
  GET_LOCATION_DETAIL_FAILURE,
  RESET_LOCATION_REDUCER
} from "./types/locationsTypes";
import { SHOW_ALERT_SUCCESS, SHOW_ALERT_ERROR } from "./types/alertTypes";
import ErrorHandler from "../lib/ErrorHandler";
import { logout } from "./authActions";

export const getLocations = filters => async dispatch => {
  const { page, query } = filters;

  try {
    dispatch({ type: LOCATIONS_LOADING });

    const endPoint = `${API_URL}/api/admin/locations`;
    const pageParams = `?page=${page}&orderBy=name&orderByDir=asc`;

    let URL = `${endPoint}${pageParams}`;

    if (query.length > 0) {
      const searchParams = `&name=${query}`;
      URL = `${URL}${searchParams}`;
    }

    const response = await axios.get(URL);

    dispatch({
      type: GET_LOCATIONS_SUCCESS,
      payload: {
        locations: response.data.data,
        links: {
          first: response.data.links.first,
          last: response.data.links.last,
          next: response.data.links.next,
          prev: response.data.links.prev
        },
        meta: {
          from: response.data.meta.from,
          to: response.data.meta.to,
          total: response.data.meta.total
        }
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_LOCATIONS_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const getLocation = id => async dispatch => {
  try {
    dispatch({ type: LOCATIONS_LOADING });

    const response = await axios.get(`${API_URL}/api/admin/locations/${id}`);

    dispatch({
      type: GET_LOCATION_DETAIL_SUCCESS,
      payload: {
        location: response.data.location
      }
    });

    return response.status;
  } catch (error) {
    dispatch({ type: GET_LOCATION_DETAIL_FAILURE });

    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const updateLocation = (id, location) => async dispatch => {
  try {
    const response = await axios.put(
      `${API_URL}/api/admin/locations/${id}`,
      location
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Localidad ${location.name} fue editada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const addLocation = location => async dispatch => {
  try {
    const response = await axios.post(
      `${API_URL}/api/admin/locations`,
      location
    );

    if (response.status === 200) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Localidad creada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const deleteLocation = id => async dispatch => {
  try {
    const response = await axios.delete(`${API_URL}/api/admin/locations/${id}`);

    if (response.status === 204) {
      dispatch({
        type: SHOW_ALERT_SUCCESS,
        message: [`Localidad eliminada satisfactoriamente.`]
      });
    }

    return response.status;
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const errorList = ErrorHandler(error.response.data.errors);
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: errorList
      });
    } else if (error.response && error.response.status === 401) {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["No autenticado."]
      });
      dispatch(logout());
    } else {
      dispatch({
        type: SHOW_ALERT_ERROR,
        message: ["Ocurrio un error, intentalo de nuevo."]
      });
    }

    return error.response.status;
  }
};

export const setSearchQuery = query => ({
  type: SET_LOCATIONS_SEARCH_QUERY,
  payload: {
    value: query
  }
});

export const setPage = page => ({
  type: SET_LOCATIONS_PAGE,
  payload: {
    value: page
  }
});

export const resetLocations = () => ({
  type: RESET_LOCATION_REDUCER
});
