import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import styled from "styled-components";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Redux
import { connect } from "react-redux";
import { setToggler } from "../actions/togglerActions";

// Components
import SidebarNavItem from "../components/SidebarNavItem";
import TogglerBtn from "../components/styles/TogglerBtn";
import MainLogo from "../images/logo.svg";

const SidebarWrapper = styled.aside`
  background-color: ${props => props.theme.secundaryColor};
  width: 200px;
  min-height: 100vh;
  transition: all 200ms ease-in-out;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    position: fixed;
    transform: translateX(${props => (props.isOpen ? "0%" : "-100%")});
    z-index: 2;
    box-shadow: 0 8px 17px 0
        ${props => (props.isOpen ? "rgba(0, 0, 0, 0.2)" : "transparent")},
      0 6px 20px 0
        ${props => (props.isOpen ? "rgba(0, 0, 0, 0.19)" : "transparent")};
  }
`;

const SidebarList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 0.5em;
`;

const SidebarLogo = styled.div`
  padding: 1em;
  border-bottom: 1px solid ${props => props.theme.transWhite};
`;

const Sidebar = ({ setToggler, toggler }) => (
  <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
    <SidebarWrapper isOpen={toggler}>
      <SidebarLogo>
        <Link to="/">
          <LazyLoadImage
            alt={`Logo Login`}
            effect="blur"
            width={`120px`}
            src={MainLogo}
            className="img-fluid d-block mx-auto"
          />
        </Link>
      </SidebarLogo>
      <SidebarList>
        <SidebarNavItem />
      </SidebarList>
      <TogglerBtn onClick={() => setToggler()}>
        {toggler ? <FiChevronLeft /> : <FiChevronRight />}
      </TogglerBtn>
    </SidebarWrapper>
  </IconContext.Provider>
);

const mapStateToProps = state => ({
  toggler: state.togglers.togglerSidebar
});

Sidebar.propTypes = {
  setToggler: PropTypes.func.isRequired,
  toggler: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  { setToggler }
)(Sidebar);
