import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import PanelLayout from "../layouts/PanelLayout";

import Dashboard from "../components/Dashboard/Dashboard";
import PharmaciesRoutes from "../components/Pharmacies/PharmaciesRoutes";
import UsersRoutes from "../components/Users/UsersRoutes";
import MembersRoutes from "../components/Members/MembersRoutes";
import ClinicsRoutes from "../components/Clinics/ClinicsRoutes";
import SpecialtiesRoutes from "../components/Specialties/SpecialtiesRoutes";
import Profile from "../components/MyAccount/Profile";
import error from "./404";
import ProvincesRoutes from "../components/Provinces/ProvincesRoutes";
import LocationsRoutes from "../components/Locations/LocationsRoutes";
import MServicesRoutes from "../components/MServices/MServicesRoutes";
import FormTypesRoutes from "../components/FormTypes/FormTypesRoutes";
import FormsRoutes from "../components/Forms/FormsRoutes";
import AreaRoutes from "../components/Area/AreaRoutes";
import METRoutes from "../components/MedicalEntityType/METRoutes";

const Panel = ({ auth, history, match }) => {
  if (!auth) {
    history.push("/login");
  }

  return (
    <PanelLayout>
      <Switch>
        <Route exact path={match.path} component={Dashboard} />
        <Route path={`${match.path}pharmacies`} component={PharmaciesRoutes} />
        <Route path={`${match.path}users`} component={UsersRoutes} />
        <Route path={`${match.path}affiliates`} component={MembersRoutes} />
        <Route path={`${match.path}clinics`} component={ClinicsRoutes} />
        <Route
          path={`${match.path}specialties`}
          component={SpecialtiesRoutes}
        />
        <Route path={`${match.path}provinces`} component={ProvincesRoutes} />
        <Route path={`${match.path}locations`} component={LocationsRoutes} />
        <Route
          path={`${match.path}medical_services`}
          component={MServicesRoutes}
        />
        <Route path={`${match.path}form_types`} component={FormTypesRoutes} />
        <Route path={`${match.path}profile/:id`} component={Profile} />
        <Route path={`${match.path}forms`} component={FormsRoutes} />
        <Route path={`${match.path}areas`} component={AreaRoutes} />
        <Route
          path={`${match.path}medical_entity_type`}
          component={METRoutes}
        />
        <Route component={error} />
      </Switch>
    </PanelLayout>
  );
};

Panel.propTypes = {
  auth: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth.isAuthenticated
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(Panel)
);
